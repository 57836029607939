import React, { useEffect, useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Row, Col, message } from "antd";

import styels from "../../page/auth/LoginPage.module.css";
import { request } from "../../share/request";
import "../../styles/Home-module.css";

import { Circles } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const navigate = useNavigate();
  const onLinkPage = (routeName) => {
    // use for link to other page
    navigate(routeName); // /category , /login
  };
  const isLogin = localStorage.getItem("isLogin");

  const [usernameInput, setUsername] = useState("");
  const [passwordInput, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const orgization = "ប្រព័ន្ធគ្រប់គ្រងរដ្ឋបាល​";
  const onFinish = async (values) => {
    setLoading(true);
    const param = {
      username: usernameInput,
      password: passwordInput,
    };

    const res = await request("user/login", "post", param);

    try {
      if (res.isSuccess) {
        console.table(res.profileData);
        if (res.profileData.islogin !== 0) {
          await new Promise((resolve) => setTimeout(resolve, 600));
          localStorage.setItem("profile", JSON.stringify(res.profileData)); // use for object data from db  to store in page
          //localStorage.setItem("permission", JSON.stringify(res.permission)); // use for object data from db  to store in page
          localStorage.setItem(
            "profileOrgData",
            JSON.stringify(res.profileOrgData)
          );
          const encodedPermission = btoa(JSON.stringify(res.permission));
          localStorage.setItem("per_option", encodedPermission); // per_option is permision option
          localStorage.setItem("token", JSON.stringify(res.access_token));
          localStorage.setItem("isLogin", "1");
          window.location.href = "/"; //
        } else {
          window.location.href = `/forgot-password?visableinput=${true}&phone=${encodeURIComponent(
            usernameInput
          )}`;
        }
      } else {
        message.warning(res.message);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onClickForgotpassword = () => {
    window.location.href = "forgot-password"; //
  };
  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loading && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#2e86c1"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>

      <div className={styels.container}>
        <div style={{ textAlign: "center", paddingBottom: 10 }}>
          <div>
            {" "}
            <img
              style={{ height: 150 }}
              // src="https://i.ibb.co/xsgtsFg/logo.png"
              src="https://png.pngtree.com/png-vector/20220527/ourmid/pngtree-system-administrator-data-hardware-server-png-image_4755206.png"
              alt="logo"
              border="0"
            />
          </div>

          <h3
            className="myfontkhmer"
            style={{ marginTop: 10, fontWeight: "bold" }}
          >
            {orgization === "" ? "iPet System Portal!" : orgization}
          </h3>
        </div>
        <div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your Username!",
                },
              ]}
            >
              <Input
                style={{ fontWeight: "bold" }}
                className={styels.textStyle}
                onChange={onChangeUsername}
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username / Phone Number"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                className={styels.textStyle}
                onChange={onChangePassword}
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item>
              <div style={{ marginBottom: 10 }}>
                <Button
                  style={{
                    width: "100%",
                    height: "40px",
                    fontWeight: "bold",
                    color: "white",
                    borderRadius: 50,
                    backgroundColor: "#2c3e50",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Log in
                </Button>
                <div
                  style={{
                    marginTop: 10,
                    flex: 1,
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <a
                    onClick={onClickForgotpassword}
                    style={{ color: "#943126" }}
                  >
                    {" "}
                    Forgot Password ?{" "}
                  </a>
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
