import React, { useState, useEffect } from "react";
import "../../styles/Home-module.css";

import {
  formateDateClient,
  formattedDate,
  getKhmerCalendarDate,
  linkPath,
  linkPathReport,
} from "../../share/helper";
import { getCurrentUser, getPermission } from "../../share/getProfile";
import dayjs from "dayjs";
import { QRCode } from "antd";
import { GrContact, GrLocation, GrMapLocation } from "react-icons/gr";
import { BiLogoTelegram } from "react-icons/bi";
import { FooterPagePrint } from "./FooterPagePrint";

const ComponentToPrint_Index2_in = ({
  reason,
  node,
  date_start,
  date_end,
  support_by,
  car_using,
  liststaff,
  Org_ImagePath,
  name_signature,
  postion_signature,
  org_cover_by,
  location,
  time_meeting,
  clothing_meeting,
  date_document,
  date_signature,
  do_in_provinceName,
  location_contact,
  org_type_code,
}) => {
  const moment = require("moment");
  require("@thyrith/momentkh")(moment);
  let api_date_document = moment(date_document, "YYYY-MM-DD");
  let api_date_signature = moment(date_signature, "YYYY-MM-DD");

  const [finalTop, setFinalTop] = useState(0); // State for the final `top` value
  const OrgProfile = getCurrentUser();
  const topMainMargine = 385;
  const textX = 130; // Adjust X coordinate as needed
  const textY = 560; // Adjust Y coordinate as needed
  let khmerDateDocument = api_date_document.toLunarDate();
  let khmerDateSingnature = api_date_signature.toLunarDate();
  // Helper to calculate the image path
  const printpath = (value) => {
    return value <= 3
      ? linkPathReport + "mission.jpg"
      : linkPathReport + "mission.jpg";
  };

  // Helper to convert to Khmer numerals
  const toKhmerNumeral = (number) => {
    const khmerDigits = ["០", "១", "២", "៣", "៤", "៥", "៦", "៧", "៨", "៩"];
    return String(number)
      .split("")
      .map((digit) => khmerDigits[digit])
      .join("");
  };
  const calculateFinalTop = () => {
    if (liststaff && liststaff.length > 0) {
      return topMainMargine + (liststaff.length - 1) * 22 + 160;
    }
    return 0;
  };
  const getKhmerPeriod = (time) => {
    const hour = dayjs(time, "HH:mm:ss").hour();
    if (hour >= 12 && hour < 13) {
      return "ថ្ងៃត្រង់"; // Midday
    } else if (hour < 12) {
      return "ព្រឹក"; // Morning
    } else {
      return "រសៀល"; // Afternoon/Evening
    }
  };

  const P_finalTop = calculateFinalTop(); // Calculate directly
  useEffect(() => {
    if (liststaff && liststaff.length > 0) {
      console.log("Calculating top value...");
      const calculatedTop = 370 + (liststaff.length - 1) * 22;
      setFinalTop(calculatedTop + 100);
      console.log("Final top value:", calculatedTop);
    } else {
      console.log("liststaff is empty or undefined.");
      setFinalTop(0);
    }
  }, [liststaff]);
  const top =
    liststaff && Array.isArray(liststaff) && liststaff.length <= 1
      ? P_finalTop + 180
      : liststaff && Array.isArray(liststaff) && liststaff.length <= 2
      ? P_finalTop + 150
      : liststaff && Array.isArray(liststaff) && liststaff.length <= 3
      ? P_finalTop + 150
      : liststaff && Array.isArray(liststaff) && liststaff.length <= 4
      ? P_finalTop + 130
      : liststaff && Array.isArray(liststaff) && liststaff.length <= 5
      ? P_finalTop + 80
      : liststaff && Array.isArray(liststaff) && liststaff.length <= 6
      ? P_finalTop + 40
      : liststaff && Array.isArray(liststaff) && liststaff.length <= 7
      ? P_finalTop + 15
      : P_finalTop;

  const Nodes1 = () => {
    return (
      <div>
        <div
          className="text-overlay"
          style={{
            top: 250,
            left: textX,
            right: 20,
          }}
        >
          <table
            className="text-on-image-name_letters_mission"
            style={{ borderCollapse: "collapse", width: "100%" }}
          >
            {" "}
            <tr>
              <td
                colSpan={2}
                style={{
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex-start",
                    textAlign: "start",
                  }}
                >
                  <br /> <br /> <br />
                  <div style={{ marginRight: 50 }}>
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; យោងចំណារ ឯកភាព របស់{" "}
                      {postion_signature} ចុះថ្ងៃទី......ខែ.....ឆ្នាំ២០២....
                      ​​មន្ត្រីរាជការសុខាភិបាល នៃ{OrgProfile.org_name_kh}{" "}
                      ដូចមានរាយនាមខាងក្រោមនេះត្រូវបានចាត់តាំងអោយ
                      ទៅបំពេញបេសកកម្មនៅ ៖{" "}
                      <span style={{ fontWeight: "bold" }}>{location}</span>។
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "100px",
                  lineHeight: "2",
                  verticalAlign: "top", // Align content at the top
                }}
                className="text-on-image-name_rightmanager_mission"
              >
                <span> </span>
              </td>
              <td style={{ verticalAlign: "top", lineHeight: "2" }}>
                <br />
                {liststaff.map((staff, index) => (
                  <span
                    key={index}
                    className="text-on-image-name_rightmanager_mission"
                  >
                    {toKhmerNumeral(index + 1)}- {staff.frontname}{" "}
                    {staff.fullname} {staff.position} <br />
                  </span>
                ))}
              </td>
            </tr>
            <tr>
              <td></td>
              <td
                style={{
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex-start",
                    textAlign: "center",
                    marginRight: 250,
                  }}
                >
                  <hr
                    style={{
                      marginTop: 2,
                      height: 1,
                      backgroundColor: "black",
                      border: "none",
                    }}
                  />
                  <span className="text-on-image-name_letters_mission">
                    សរុប {liststaff.length} នាក់
                  </span>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  };
  const Nodes = () => {
    return (
      <div>
        <div
          className="text-overlay"
          style={{
            top: top,
            left: textX,
            right: 20,
          }}
        >
          <br />
          <br />
          <table
            className="text-on-image-name_letters_mission"
            style={{ borderCollapse: "collapse" }}
          >
            <tr>
              <td
                style={{
                  width: "80px",
                  lineHeight: "2",
                  verticalAlign: "top", // Align content at the top
                }}
                className="text-on-image-name_rightmanager_mission"
              >
                <div>
                  <span> ដើម្បី :</span>
                </div>
              </td>
              <td style={{ verticalAlign: "top", lineHeight: "2" }}>
                {reason}
                <br />
              </td>
            </tr>
            <tr>
              <td style={{ width: "80px", lineHeight: "2" }}>ចេញដំណើរ :</td>
              <td style={{ lineHeight: "2" }}> {date_start} </td>
            </tr>
            <tr>
              <td style={{ width: "80px", lineHeight: "2" }}>ផុតកំណត់ :</td>
              <td style={{ lineHeight: "2" }}> {date_end} </td>
            </tr>
            <tr>
              <td style={{ width: "100px", lineHeight: "2" }}>មធ្យោបាយ :</td>
              <td style={{ lineHeight: "2" }}> {car_using} </td>
            </tr>
            <tr>
              <td style={{ width: "100px", lineHeight: "2" }}>ឧបត្ថមដោយ :</td>
              <td style={{ lineHeight: "2" }}> {support_by} </td>
            </tr>
            {/* <tr>
              <td colSpan={2}>
                <span style={{ marginBottom: 10 }}>
                  សេចក្តីដូចបានជំរាបជូនតាមកម្មវត្ថុ និងយោងខាងលើ សូមលោកប្រធាន
                  មេត្តាសម្រេចតាមការគួរ​។
                </span>
              </td>
            </tr> */}
            <tr>
              <td
                colSpan={2}
                style={{
                  alignItems: "start",
                }}
              >
                <div
                  style={{
                    marginLeft: "300px",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <br />
                  {/*                   
                  <span>បានឃើញ និង.............</span>
                  <span></span>

                  <span>..........ថ្ងៃទី........ខែ.......ឆ្នាំ២០២.....</span> */}

                  <span className="text-on-image-name_rightmanager_mission">
                    {postion_signature}
                  </span>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  };

  const Header = () => {
    return (
      <div className="text-on-image-name_rightmanager_mission">
        <div
          className="text-overlay"
          style={{
            top: 30,
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="text-on-image-name_rightmanager_mission">
            <span>ព្រះរាជាណាចក្រកម្ពុជា</span> <br />
            <span>ជាតិ សាសនា ព្រះមហាក្សត្រ</span>
            <br />
            <img
              src={linkPath + "tacteing_font.jpg"}
              alt="tacteing font"
              style={{ width: "20%" }}
            />
          </div>
        </div>
        <div
          className="text-overlay"
          style={{
            marginLeft: 50,
            top: 80,
            display: "flex",
            width: "100%",
            alignItems: "start",
          }}
        >
          <div className="text-on-image-name_rightmanager_mission">
            <img
              src={Org_ImagePath}
              alt="Organization Logo"
              style={{ width: "70px", height: "70px", marginBottom: "5px" }}
            />
            <span>
              {OrgProfile.org_type <= 3 && (
                <span className="text-on-image-name_rightmanager_mission">
                  <br /> រដ្ឋបាលខេត្ត{do_in_provinceName}
                </span>
              )}
              {OrgProfile.org_type >= 4 && (
                <span className="text-on-image-name_rightmanager_mission">
                  <br /> មន្ទីរសុខាភិបាលនៃរដ្ឋបាលខេត្ត
                </span>
              )}
            </span>
            {OrgProfile.org_type != 1 && (
              <span className="text-on-image-name_rightmanager_mission">
                <br /> {org_cover_by}
              </span>
            )}{" "}
            <br />
            <span>{OrgProfile.org_name_kh}</span>
            <br />{" "}
            <span className="text-on-image-name_letters_mission">
              លេខ៖...........
            </span>
          </div>
        </div>

        <div
          className="text-overlay"
          style={{
            // backgroundColor: "#a3e4d7",
            right: 50,
            top: 200,
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{ textAlign: "end" }}
            className="text-on-image-name_letters_mission"
          >
            <span>{khmerDateDocument}</span>
            <br />
            <span>
              ធ្វើនៅ{do_in_provinceName}{" "}
              {formattedDate(formateDateClient(date_document))}
            </span>
          </div>
        </div>

        <div
          className="text-overlay"
          style={{
            top: 270,
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="text-on-image-name_rightmanager_mission">
            <span>លិខិតបញ្ជាបេសកកម្ម</span>
            <br />
            <hr
              style={{
                marginTop: 5,
                height: 2,
                backgroundColor: "black",
                border: "none",
              }}
            />
            {/* <img
              src={linkPath + "tacteing_font_001.jpg"}
              alt="tacteing font"
              style={{ width: "20%" }}
            /> */}
            <br />
          </div>
        </div>
      </div>
    );
  };
  const HeaderSecondPage = () => {
    return (
      <div>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="text-on-image-name_rightmanager_mission"
            style={{ textAlign: "center" }}
          >
            <span>ព្រះរាជាណាចក្រកម្ពុជា</span> <br />
            <span>ជាតិ សាសនា ព្រះមហាក្សត្រ</span>
            <br />
          </div>
          <img
            src={linkPath + "tacteing_font.jpg"}
            alt="tacteing font"
            style={{ width: 60 }}
          />
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "start",
          }}
        >
          <div className="text-on-image-name_rightmanager_mission">
            <img
              src={Org_ImagePath}
              alt="Organization Logo"
              style={{
                marginLeft: 20,
                width: "70px",
                height: "70px",
                marginBottom: "5px",
              }}
            />
            <span>
              {OrgProfile.org_type <= 3 && (
                <span className="text-on-image-name_rightmanager_mission">
                  <br /> រដ្ឋបាលខេត្ត{do_in_provinceName}
                </span>
              )}
              {OrgProfile.org_type >= 4 && (
                <span className="text-on-image-name_rightmanager_mission">
                  <br /> មន្ទីរសុខាភិបាលនៃរដ្ឋបាលខេត្ត
                </span>
              )}
            </span>
            {OrgProfile.org_type != 1 && (
              <span className="text-on-image-name_rightmanager_mission">
                <br /> {org_cover_by}
              </span>
            )}{" "}
            <br />
            <span>{OrgProfile.org_name_kh}</span>
            <br />{" "}
            <span className="text-on-image-name_letters_mission">
              លេខ៖...........
            </span>
          </div>
        </div>

        <div
          style={{
            // backgroundColor: "#a3e4d7",
            right: 50,
            top: 200,
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{ textAlign: "end" }}
            className="text-on-image-name_letters_mission"
          >
            <span>{khmerDateDocument}</span>
            <br />
            <span>
              ធ្វើនៅ{do_in_provinceName}{" "}
              {formattedDate(formateDateClient(date_document))}
            </span>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="text-on-image-name_rightmanager_mission"
            style={{ textAlign: "center" }}
          >
            <span>លិខិតចុះទិដ្ឋាការធ្វើដំណើរ</span>
            <br />
            <hr
              style={{
                marginTop: 5,
                height: 2,
                backgroundColor: "black",
                border: "none",
              }}
            />
            {/* <img
              src={linkPath + "tacteing_font_001.jpg"}
              alt="tacteing font"
              style={{ width: "20%" }}
            /> */}
            <br />
          </div>
        </div>
      </div>
    );
  };

  const SingleStaffPage = ({ staff, index }) => (
    <div className="text-on-image-name_letters_mission">
      <HeaderSecondPage />

      <div style={{ width: "100%", marginLeft: 180 }}>
        <table>
          <tr>
            <td style={{ width: "150px", lineHeight: "1.8" }}>ផ្តល់អោយ</td>
            <td style={{ lineHeight: "1.8" }}>
              : {staff.frontname} {staff.fullname}
            </td>
          </tr>
          <tr>
            <td style={{ width: "150px", lineHeight: "1.8" }}>
              ថានះ និងទីកន្លែងធ្វើការ
            </td>
            <td style={{ lineHeight: "1.8" }}>: {staff.position}</td>
          </tr>
          <tr>
            <td style={{ width: "150px", lineHeight: "1.8" }}>
              បំពេញបេសកកម្មនៅ{" "}
            </td>
            <td style={{ lineHeight: "1.8" }}>: {location}</td>
          </tr>
          <tr>
            <td style={{ width: "150px", lineHeight: "1.8" }}>
              លិខិតបញ្ជាបេសកកម្ម{" "}
            </td>
            <td style={{ lineHeight: "1.8" }}>
              : លិខិតលេខ .................ចុះ
              {formattedDate(formateDateClient(date_document))}
            </td>
          </tr>
          <tr>
            <td style={{ width: "150px", lineHeight: "1.8" }}>ចាប់ពី </td>
            <td style={{ lineHeight: "1.8" }}>
              : {date_start} ដល់ {date_end}
            </td>
          </tr>
          <tr>
            <td style={{ width: "150px", lineHeight: "1.8" }}>
              មធ្យោបាយធ្វើរដំណើរ​{" "}
            </td>
            <td style={{ lineHeight: "1.8" }}>: {car_using}</td>
          </tr>
          <tr>
            <td style={{ width: "150px", lineHeight: "1.8" }}>ឧបត្ថមដោយ </td>
            <td style={{ lineHeight: "1.8" }}>: {support_by}</td>
          </tr>
        </table>
        <div
          style={{
            display: "flex",
            marginLeft: 200,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span>ថ្ងៃទី........ខែ.......ឆ្នាំ២០២.....</span>
          <span className="text-on-image-name_rightmanager_mission">
            {postion_signature}
          </span>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <table class="styled-table-mission">
        <thead>
          <tr>
            <th class="khmer-text">
              ទីកន្លែងចេញដំណើរ <br /> និងមកដល់
            </th>
            <th class="khmer-text">ខែ ខែ ថ្ងៃ</th>
            <th class="khmer-text">ចំងាយ</th>
            <th class="khmer-text">កន្លែងស្នាក់នៅ</th>
            <th class="khmer-text">ហត្ថលេខា និងត្រាអង្គភាព</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="khmer-text">
              ទីកន្លែងចេញដំណើរ
              <br />
              <br />
              <br />
              ..............
            </td>
            <td class="khmer-text">
              <br />
              <br />
              <br />
              ..............
              <br />
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="khmer-text">
              ទីកន្លែងទៅដល់
              <br /> <br />
              ..............
              <br />
              <br />
              ទីកន្លែងចេញដំណើរ
              <br /> <br />
              ..............
              <br />
            </td>
            <td class="khmer-text">
              <br /> <br />
              ..............
              <br />
              <br />
              <br /> <br />
              ..............
              <br />
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="khmer-text">
              ទីកន្លែងទៅដល់
              <br /> <br />
              ..............
              <br />
              <br />
              ទីកន្លែងចេញដំណើរ
              <br /> <br />
              ..............
              <br />
            </td>
            <td class="khmer-text">
              <br /> <br />
              ..............
              <br />
              <br />
              <br /> <br />
              ..............
              <br />
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
  //   return (
  //     <div>
  //       {/* first  page  */}
  //       <div className="print-page-mission">
  //         <div style={{ marginLeft: 20 }}>
  //           <Header />
  //           <Nodes1 />
  //           {/* <img src={printpath(3)} alt="Leave Report" className="print-image" /> */}
  //           {/* Map over liststaff to render each staff's info */}

  //           <Nodes />
  //         </div>
  //       </div>
  //       {/* second page  */}
  //       <div
  //         className="print-page-mission"
  //         style={{ marginRight: 50, marginLeft: 50, marginTop: 50 }}
  //       >
  //         {liststaff.map((staff, index) => (
  //           <div key={index}>
  //             <SingleStaffPage staff={staff} index={index} />
  //           </div>
  //         ))}
  //       </div>
  //       <Footer />
  //     </div>
  //   );
  // };

  return (
    <div>
      {/* First Page */}
      <div className="print-page-mission">
        <div style={{ marginLeft: 20 }}>
          <Header />
          <Nodes1 />
          <Nodes />
        </div>
      </div>

      {/* Second Page */}
      {liststaff.map((staff, index) => (
        <div
          key={index}
          className="single-staff-page"
          style={{
            margin: "50px",
            pageBreakAfter: index === liststaff.length - 1 ? "auto" : "always",
          }}
        >
          <SingleStaffPage staff={staff} index={index} />
        </div>
      ))}

      {/* Footer */}
      <div className="print-page-mission">
        <FooterPagePrint location_contact={location_contact} />
      </div>
    </div>
  );
};

export default ComponentToPrint_Index2_in;
