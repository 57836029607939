import "../../styles/Home-module.css";
import "./Model-Setting-Grid.css";
import QRCode from "react-qr-code";
import { BiLogoTelegram } from "react-icons/bi";
import { GrLocation } from "react-icons/gr";
import { getCurrentUser } from "../../share/getProfile";

export const FooterPagePrint = ({ location_contact }) => {
  const OrgProfile = getCurrentUser();
  return (
    <div
      className="footer"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        position: "absolute",
        bottom: "0",
        width: "100%",
        textAlign: "center",
        borderTop: "0.5px solid #e5e7e9",
        padding: "1px",
        marginBottom: "10px",
      }}
    >
      <div
        style={{
          marginLeft: "40px",
          padding: 1,
          width: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          textAlign: "start",
        }}
      >
        <span class="khmer-text-footer">
          © {new Date().getFullYear()} .{OrgProfile.org_name_kh}
        </span>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <GrLocation
            style={{ height: 13, width: 13, marginRight: 2, color: "#0667b1" }}
          />
          <span class="khmer-text-footer"> {location_contact}</span>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <BiLogoTelegram
            style={{ height: 13, width: 13, marginRight: 2, color: "#0667b1" }}
          />
          <span class="khmer-text-footer"> {OrgProfile.domain}</span>
        </div>
      </div>
      <div style={{ marginRight: "90px" }}>
        <QRCode
          fgColor="#0667b1" // 🔴 ពណ៌ក្រហម
          size={40}
          value={OrgProfile.domain}
        />
      </div>
    </div>
  );
};
