import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import OtpInput from "otp-input-react";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { request } from "../../share/request";

import { toast, Toaster } from "react-hot-toast";

import "./ForgotPassword.css"; // Import your CSS file
import ChangePassword from "./ChangePassword";
import { openNotification } from "../../share/message";
import { message, Modal, Form, Input, Button } from "antd";
import { LockOutlined } from "@ant-design/icons";
import styels from "../../page/auth/LoginPage.module.css";
import GetLoading from "../../share/getLoading";
const ForgotPassword = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const phone = urlParams.get("phone");
  const visableinput = urlParams.get("visableinput");
  console.log("phone number from first loging : ", phone); // Outputs: 0885290240
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState(phone ? "+855" + phone : "");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const [correctOPT, setCorrectOPT] = useState(false);
  const [visableUpdatePassword, setVisableUpdatePassword] = useState(false);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [onlyPhoneNumber, setOnlyPhoneNumber] = useState(phone);
  const onClose = () => {
    setVisableUpdatePassword(false);
  };
  const onFinish = async (values) => {
    try {
      var data = {
        username: onlyPhoneNumber,
        password: values.password,
        confirmPassword: values.passwordConfirm,
      };
      const res = await request("user/set-password", "post", data);
      // Simulate an asynchronous save operation (replace with your actual save logic)

      if (res.isSuccess) {
        setVisableUpdatePassword(false);
        setLoadingSpin(true);
        await new Promise((resolve) => setTimeout(resolve, 4000));
        localStorage.setItem("isLogin", null || "");
        window.location.href = "/login";
      } else if (values.password != values.confirmPassword) {
        message.error(" password is not match !");
      }
    } catch (error) {
      message.error(" password is not match !");
    } finally {
      setLoadingSpin(false);
    }
  };

  async function onCheckUserName() {
    let phoneNumber = onlyPhoneNumber;
    console.log("Checking", phoneNumber);
    if (!phoneNumber) {
      openNotification("error", "error", "Please enter a phone number");
      return;
    }
    try {
      if (phoneNumber.charAt(0) !== "0") {
        phoneNumber = "0" + phoneNumber;
      }

      setOnlyPhoneNumber(phoneNumber);
      // console.log("Checking user befor change  ", onlyPhoneNumber);
      // console.log("Phone number after changed:", phoneNumber);

      setLoading(true);

      try {
        setLoading(true);
        const response = await request("usersendotpcode", "POST", {
          phoneNumber: phoneNumber,
        });
        if (response && response.success == true) {
          setShowOTP(true);
          message.success("OTP has been sent to your phone.");
          setVisableUpdatePassword(true);
        } else {
          message.error(response.error);
        }
      } catch (error) {
        console.error(error);
        message.warning("Something went wrong while sending OTP.");
      } finally {
        setLoading(false);
      }
    } catch (error) {
      message.warning(error);
    }
  }

  const onChangePhoneNumber = (value, country) => {
    setPh(value); // Full phone number with country code
    const phoneWithoutCountryCode = value.substring(country.dialCode.length);
    setOnlyPhoneNumber(phoneWithoutCountryCode); // Store phone number without country code
  };

  const onOTPVerify = async () => {
    console.log("OTP", otp);

    try {
      setLoading(true);
      const res = await request("userverifyotp", "POST", {
        phoneNumber: onlyPhoneNumber,
        otp: otp,
      });

      if (res && res.success === true) {
        message.success("OTP Verified successfully!");
        setCorrectOPT(true);
      } else {
        message.error("Invalid OTP or OTP expired.");
      }
    } catch (error) {
      message.error("Something went wrong during OTP verification.");
    } finally {
      setLoading(false);
    }
  };
  const login = () => {
    window.location.href = "/"; //
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <section className="forgot-password-section">
        <div>
          <Toaster toastOptions={{ duration: 4000 }} />

          {user ? (
            <ChangePassword />
          ) : (
            <div className="form-container">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src="https://img.freepik.com/premium-vector/otp-authentication-secure-verification_7087-3082.jpg"
                  alt="otp"
                  style={{ width: "60%", height: "60%", borderRadius: "50%" }}
                />
              </div>

              {showOTP ? (
                <>
                  <label htmlFor="otp" className="otp-label">
                    Enter OTP
                  </label>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      OTPLength={4}
                      otpType="number"
                      disabled={false}
                      autoFocus
                      inputClassName="otp-input"
                    />
                  </div>
                  <button onClick={onOTPVerify} className="submit-button">
                    {loading && (
                      <CgSpinner size={20} className="loading-spinner" />
                    )}
                    <span>Verify OTP</span>
                  </button>
                </>
              ) : (
                <>
                  <PhoneInput
                    country={"kh"}
                    inputStyle={{
                      height: 50,
                      fontWeight: "bold",
                      fontSize: 20,
                      borderRadius: 4,
                    }}
                    value={ph}
                    disabled={!!phone} // Disable input if `ph` has a value
                    onChange={onChangePhoneNumber}
                  />
                  <button onClick={onCheckUserName} className="submit-button">
                    {loading && (
                      <CgSpinner size={20} className="loading-spinner" />
                    )}
                    <span>Send code via SMS</span>
                  </button>
                </>
              )}
            </div>
          )}
        </div>
        <a onClick={login} style={{ color: "#943126", cursor: "pointer" }}>
          login ?
        </a>
      </section>

      <Modal
        title="កែប្រែលេខសម្ងាត់ / Update Password "
        className={styels.textStyle}
        open={correctOPT}
        footer={[]}
        onCancel={onClose}
      >
        <div>
          <Form
            name="normal_login"
            onFinish={onFinish}
            initialValues={{
              remember: true,
            }}
          >
            <Form.Item
              className={styels.textStyle}
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                className={styels.textStyle}
                prefix={<LockOutlined />}
                type="password"
                placeholder="New Password"
              />
            </Form.Item>
            <Form.Item
              name="passwordConfirm"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                className={styels.textStyle}
                prefix={<LockOutlined />}
                type="password"
                placeholder="Comfirm New Password"
              />
            </Form.Item>

            <Form.Item>
              <div>
                <Button
                  className={styels.textStyle}
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                >
                  Update
                </Button>
              </div>
            </Form.Item>
          </Form>

          <div></div>
        </div>
      </Modal>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <GetLoading />
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
