// import Pagination from "react-bootstrap/Pagination";
import axios from "axios";
import Icon from "@ant-design/icons";
import React, { useEffect, useState, useRef } from "react";
import Table from "react-bootstrap/Table";

import { request } from "../../share/request";
import { Stack, TabContent } from "react-bootstrap";
import moment from "moment";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import dayjs from "dayjs";

import {
  IdcardOutlined,
  WomanOutlined,
  ManOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  UsergroupAddOutlined,
  UserDeleteOutlined,
  DownOutlined,
} from "@ant-design/icons";

import {
  Dropdown,
  Card,
  Pagination,
  Drawer,
  Checkbox,
  Modal,
  Button,
  Form,
  Input,
  Item,
  UserOutlined,
  Select,
  Space,
  Tag,
  Spin,
  notification,
  Avatar,
  FloatButton,
  Upload,
  ConfigProvider,
  TreeSelect,
  DatePicker,
} from "antd";
import "../../styles/Home-module.css";
import "./Profile.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS
import {
  formateDateClient,
  formateDateClientTime,
  formateDateSystem,
  linkPath,
  linkPathUser,
} from "../../share/helper";
import { openNotification } from "../../share/message";

import { Circles } from "react-loader-spinner";
import { MdOutlineEdit, MdOutlineDelete } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";
import CardComponentFront from "../user/CardComponentFront";
import CardComponentBack from "../user/CardComponentBack";

import { useReactToPrint } from "react-to-print";
import { getCurrentUser, getPermission } from "../../share/getProfile";

import "react-datepicker/dist/react-datepicker.css";

import { getJobStatus } from "../../share/getStatusContent";
import { CiCamera } from "react-icons/ci";
import { FiPhoneCall } from "react-icons/fi";
import { BiIdCard } from "react-icons/bi";
import { IoDocumentTextOutline } from "react-icons/io5";
import UserDetail from "../user/userdetail";

import GetLoading from "../../share/getLoading";
import { Cropper } from "react-cropper";

const { Option } = Select;
const getProfileID = getCurrentUser();
const ProfileUser = () => {
  const [isModalOpenCropImage, setModalOpenCropImage] = useState(false);
  const cropperRef = useRef(null);
  const [objFilter, setObjFilter] = useState({
    txtSearch: getProfileID.username,
    org_code: getProfileID.org_code,
  });
  const [deparment_id, setDeparmentID] = useState();
  const [position_id, setPositionID] = useState();
  const [skill_id, setSkillID] = useState();
  const [frontImage, setFrontImage] = useState("");
  const [backImage, setBackImage] = useState("");
  const [visibleDraw, setVisableDraw] = useState(false);
  const [enable, setEnable] = useState(false);
  const [disable, setDisable] = useState(false);
  const [disableEditDetail, setDisableEditDetail] = useState(true);
  const [enable_car, setEnable_Car] = useState(false);
  const [disable_car, setDisable_Car] = useState(false);

  const [form] = Form.useForm();
  const [list, setList] = useState([]);
  const [total, setTotal] = useState([]);
  const [getPostionList, setGetPositionList] = useState([]);
  const [ListLevelAPI, setListLevel] = useState([]);
  const [ListSkillAPI, setListSkillAPI] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visabledelete, setvisabledelete] = useState(false);
  const [id, setId] = useState("");
  const [phone, setPhone] = useState("");
  const [staffname, setStaffname] = useState("");
  const [gender, setGender] = useState([]);
  const [roleid, setRoleID] = useState([]);
  const [department, setDepartment] = useState("");
  const [position, setPosition] = useState("");
  const [frontname, setFrontname] = useState("");
  const [listFrontName, setListFrontName] = useState([]);
  const [status, setStatus] = useState([]);
  const [shiftid, setShiftID] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [departmentlist, setDepartmentList] = useState([]);

  const [loading, setLoading] = useState(true);

  const [textSearch, setTextSearch] = useState("");

  const [positionslist, setPositionslist] = useState([]);
  const [loadingSpin, setLoadingSpin] = useState(false);

  const [idnumber, setIDNumber] = useState();
  const [staffname_eng, setStaffnameEnglish] = useState();
  const [dob, setDOB] = useState(dayjs());
  const [dob_value, setDOBValue] = useState(dayjs());
  const [level, setLevel] = useState([]);
  const [joinwork, setJoinWork] = useState(dayjs());
  const [skill, setSkill] = useState([]);
  const [updateleveldate, setUpgradeLevelDate] = useState(dayjs());
  const [stafftype, setStaffType] = useState(null);
  const [no_update, setNoUpdate] = useState(null);
  const [listCard, setListCard] = useState([]);

  const componentRefFront = useRef(null);
  const componentRefBack = useRef(null);
  const [shouldPrintFront, setShouldPrintFront] = useState(false);
  const [shouldPrintBack, setShouldPrintBack] = useState(false);
  const [dataFetchedFront, setDataFetchedFront] = useState(false);
  const [dataFetchedBack, setDataFetchedBack] = useState(false);
  const [datarole, setDataRole] = useState([]);

  const [listCount, setDataCount] = useState([]);
  // -----------------  permisstion page --------------------
  const userPermission = getPermission();
  const getTokenData = localStorage.getItem("token");

  const [printNone, setPrintNone] = useState(0);

  const staffValue = userPermission.find(
    (permission) => permission.pagename === "staff"
  ) || { add: 0, edit: 0, deleted: 0, print: 0 };
  const {
    add: addvalue,
    edit: Editvalue,
    deleted: deletevalue,
    print: displayPrint,
  } = staffValue;
  // -----------------  end permisstion page --------------------
  const printFunctionFront = useReactToPrint({
    content: () => componentRefFront.current,
  });
  const printFunctionBack = useReactToPrint({
    content: () => componentRefBack.current,
  });

  useEffect(() => {
    console.log("Profile Name : " + getProfileID.fullname);
    // This effect runs when the component mounts
    setShouldPrintFront(false);
    setShouldPrintBack(false); // Reset shouldPrint after mounting
    setPrintNone(displayPrint ? "" : "none");
  }, []); // Empty dependency array means it runs once on mount
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once - while scrolling down
    });
  }, []);
  useEffect(() => {
    getListWorkStation();
    getListSkill();
    const fetchDataAndPrint = async () => {
      try {
        await getList(objFilter);
        setDataFetchedFront(true);
        setDataFetchedBack(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataAndPrint();

    if (getProfileID.org_code) {
      console.log("Updated getProfileOrg:", getProfileID.org_code); // Check if idorg updates
      setFrontImage(""); // Reset images on idorg change
      setBackImage("");
      (async () => {
        await getImageLinkOrganization();
      })();
      console.log("front card :", frontImage);
      console.log("back card :", backImage);
    }
  }, [textSearch, listCard]);

  useEffect(() => {
    if (dataFetchedFront && shouldPrintFront) {
      printFunctionFront();
      setShouldPrintFront(false);
      setDataFetchedFront(false); // Reset dataFetched after printing
    }
    if (dataFetchedBack && shouldPrintBack) {
      printFunctionBack();
      setShouldPrintBack(false);
      setDataFetchedBack(false); // Reset dataFetched after printing
    }
  }, [
    shouldPrintFront,
    shouldPrintBack,
    printFunctionFront,
    printFunctionBack,
    dataFetchedFront,
    dataFetchedBack,
  ]);
  useEffect(() => {
    if (textSearch !== "") {
      var objTmp = {
        ...objFilter,
        txtSearch: textSearch,
        page: textSearch === "" ? 1 : objFilter.page,
      };
      setObjFilter(objTmp);
      getList(objTmp);
      setLoading(false);
    } else {
      var objTmp = {
        ...objFilter,
        txtSearch: "",
        page: 1,
      };

      setObjFilter(objTmp);
      getList(objTmp);
    }
  }, [textSearch]);

  const getList = async (objFilter) => {
    const params = new URLSearchParams({
      txtSearch: getProfileID.username,
      org_code: getProfileID.org_code,
    });

    try {
      console.log("params search : " + params.toString());
      const res = await request("userprofilebyid?" + params, "get", {});
      await new Promise((resolve) => setTimeout(resolve, 400));

      console.log(res);
      if (res) {
        setList(res.list);
        console.log("list of user id : ", res.list);

        setDepartmentList(res.listdepartment);
        setListFrontName(res.listfrontname);
        setDataRole(res.listrole);
        setDataCount(res.listcount[0]);
        console.log("list of", res.listcount);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getListSkill = async () => {
    // create function success
    const res = await request("userlevel", "get", {});
    if (res) {
      setListSkillAPI(res.ListSkill);
      setListLevel(res.ListLevel);
    }
  };
  const [treeData, setTreeData] = useState([]);
  const [treeValueSelect, setTreeValueSelect] = useState(undefined);

  const getListWorkStation = async () => {
    // create function success
    const res = await request("userlocationwork", "get", {});
    if (res) {
      const workLocations = res.get_API_WorkLocation[0];
      const formattedTreeData = buildTreeData(workLocations);
      setTreeData(formattedTreeData);
    }
  };
  const buildTreeData = (workLocations) => {
    // Initialize an empty map to store parent-child relationships
    const parentChildMap = {};

    // Traverse the work locations and build the parent-child map
    workLocations.forEach((location) => {
      const {
        province_code,
        province_name_kh,
        phd_cod,
        phd_name_kh,
        od_code,
        od_name_kh,
        hc_code,
        hc_name_kh,
      } = location;

      // Map health center (hc) under commune (od), commune under district (phd), and district under province
      if (!parentChildMap[province_code]) {
        parentChildMap[province_code] = {
          title: province_name_kh,
          value: `${province_code}`,
          children: {},
        };
      }

      const provinceNode = parentChildMap[province_code];

      if (!provinceNode.children[phd_cod]) {
        provinceNode.children[phd_cod] = {
          title: phd_name_kh,
          value: `${province_code}-${phd_cod}`,
          children: {},
        };
      }

      const phdNode = provinceNode.children[phd_cod];

      if (!phdNode.children[od_code]) {
        phdNode.children[od_code] = {
          title: od_name_kh,
          value: `${province_code}-${phd_cod}-${od_code}`,
          children: {},
        };
      }

      const odNode = phdNode.children[od_code];

      if (!odNode.children[hc_code]) {
        odNode.children[hc_code] = {
          title: hc_name_kh,
          value: `${province_code}-${phd_cod}-${od_code}-${hc_code}`,
        };
      }
    });

    // Convert the parent-child map to tree data array
    const treeData = Object.values(parentChildMap).map((province) => ({
      ...province,
      children: Object.values(province.children).map((phd) => ({
        ...phd,
        children: Object.values(phd.children).map((od) => ({
          ...od,
          children: Object.values(od.children),
        })),
      })),
    }));

    return treeData;
  };
  const onChange = (value, label, extra) => {
    console.log("Selected:", value, label, extra);
    setTreeValueSelect(value); // Update selected value in state
  };

  const onCloseModal = () => {
    inputRef.current.value = null;
    setVisible(false);
    setId("");
    setPhone("");
    setIDNumber("");
    setStaffnameEnglish("");
    setStaffname("");
    setRoleID("");
    setDepartment("");
    setSelectedPosition("");
    setFrontname("");
    setStatus("");
    setGender("");
    setDOB(dayjs("1990-01-01").format("YYYY-MM-DD"));
    setSkill(null);
    setLevel(null);
    setJoinWork(dayjs("1990-01-01").format("YYYY-MM-DD"));
    setStaffType(null);
    setUpgradeLevelDate(dayjs("1990-01-01").format("YYYY-MM-DD"));
    setImagesave(null);
    setSelectedFile(null);
    setImagePreview(null);
    setEnable(false);
    setDisable(true);
    setEnable_Car(false);
    setDisable_Car(true);
  };

  const onSave = async (item) => {
    const fields = [
      { value: phone, message: "Phone Number is not null" },
      { value: staffname, message: "Staff Name is not null" },
      { value: roleid, message: "Role ID is not null" },
      { value: department, message: "Department is not null" },
      { value: selectedPosition, message: "Position is not null" },
      { value: frontname, message: "Front Name is not null" },
      { value: status, message: "Status is not null" },
      { value: idnumber, message: "ID Number is not null" },
      { value: dob, message: "DOB is not null" },
      { value: level, message: "Level is not null" },
      { value: skill, message: "Skill is not null" },
      { value: joinwork, message: "Join Work is not null" },
      { value: updateleveldate, message: "Update Level Date is not null" },
      { value: stafftype, message: "Staff Type is not null" },
    ];

    for (const field of fields) {
      if (
        !field.value ||
        (Array.isArray(field.value) && field.value.length === 0)
      ) {
        openNotification("success", "Success", field.message);
        return; // Stop execution if any field is empty
      }
    }

    const formData = new FormData();
    formData.append("username", phone);
    formData.append("fullname", staffname);
    formData.append("gender", gender);
    formData.append("roleid", roleid);
    formData.append("department", deparment_id);
    formData.append("position", position_id);
    formData.append("frontname", frontname);
    formData.append("jobstatus", status);
    formData.append("dob", dob);
    formData.append("idnumber", idnumber);
    formData.append("staffname_eng", staffname_eng);
    formData.append("joinwork", joinwork);
    formData.append("skill", skill_id);
    formData.append("level", level);
    formData.append("updateleveldate", updateleveldate);
    formData.append("stafftype", stafftype);
    formData.append("shiftid", shiftid);
    formData.append("no", no_update);
    formData.append("image", form.getFieldValue("image"));
    formData.append("showadmin", enable == true ? 1 : disable == true ? 0 : 0);
    formData.append(
      "car_data",
      enable_car == true ? 1 : disable_car == true ? 0 : 0
    );

    if (id !== "") {
      formData.append("id", id);
    }

    // if (imageSave !== null) {
    //   formData.append(
    //     id !== "" ? "fileimage_update" : "file_image",
    //     imageSave,
    //     imageSave.filename
    //   );
    // }

    // Log FormData content

    if (imageSave !== null) {
      const blob = await fetch(imageSave).then((res) => res.blob());
      const imageFile = new File([blob], "logo.png", {
        type: "image/png",
      });
      formData.append(
        id !== "" ? "fileimage_update" : "file_image",
        imageFile,
        imageFile.filename
      );
    }

    console.table("FormData content:");
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const method = id === "" ? "post" : "put";
      const res = await request("user", method, formData);

      if (res && res.success === true) {
        setVisible(false);
        setLoadingSpin(true);
        await new Promise((resolve) => setTimeout(resolve, 5000));
        openNotification("success", "Success", res.message);
        inputRef.current.value = null;
        getList(objFilter);
      } else {
        openNotification("error", "Error", res.message);
        getList(objFilter);
      }
    } catch (error) {
      openNotification("error", "Error", error.message);
    } finally {
      setLoadingSpin(false);
    }
  };

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = list.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page changes

  const onChangePhone = (event) => {
    setPhone(event.target.value);
  };
  const onChangeStaffname = (event) => {
    setStaffname(event.target.value);
  };
  const onChangeGender = (value) => {
    setGender(value);
  };

  const onChangeProvider = (value) => {
    //  setProvider(providerData[value]);
    setRoleID(value);
    console.log("this id role: " + value);
  };

  const onChangeDepartment = async (value) => {
    setDeparmentID(value);
    console.log("this id department: " + value);
    setDepartment(value);
    const res = await request("user/department/" + value, "get", {});

    if (res && res.listPosition) {
      // Extract positions from the nested structure
      const positions = Array.isArray(res.listPosition[0])
        ? res.listPosition[0]
        : [];
      setPositionslist(positions);
      setSelectedPosition(null);
      console.log("This is the list position :", JSON.stringify(positions));
    } else {
      console.error("Invalid response structure:", res);
    }
  };

  const onChangeFrontName = (event) => {
    setFrontname(event);
  };
  const onChangeEnglishName = (event) => {
    setStaffnameEnglish(event.target.value);
  };
  const onChangeIDNumber = (event) => {
    setIDNumber(event.target.value);
  };
  const onChangeStatus = (value) => {
    setStatus(value);
  };
  const [imageDelete, setImageDelete] = useState(null);
  const onDelete = (item) => {
    setId(item.id);
    setNoUpdate(item.no);
    setImageDelete(item.image);
    setStaffname(item.fullname);
    setvisabledelete(true);
  };

  const onClickEdit = async (item) => {
    setShiftID(item.shift_id);
    setDeparmentID(item.department);
    setPositionID(item.position);
    setSkillID(item.skill);
    try {
      setLoadingSpin(true);
      setDisableEditDetail(false);
      if (item.department != null) {
        const res = await request(
          "user/department/" + item.department,
          "get",
          {}
        );

        if (res && res.listPosition) {
          // Extract positions from the nested structure
          const positions = Array.isArray(res.listPosition[0])
            ? res.listPosition[0]
            : [];

          setPositionslist(positions);
          setListLevel(res.ListLevel);
          setListSkillAPI(res.ListSkill);
          console.log("This is the list:", JSON.stringify(positions));
        } else {
          console.error("Invalid response structure:", res);
        }
      }

      setId(item.id);
      setPhone(item.username);
      setIDNumber(item.idnumber);
      setStaffnameEnglish(item.staffname_eng);
      setStaffname(item.fullname);
      setRoleID(item.role_id);
      setDepartment(item.department_name);
      setSelectedPosition(item.position_name);
      setFrontname(item.frontname);
      setStatus(item.jobstatus);
      setGender(item.gender);
      setImagePreview(linkPath + item.image);
      setDOB(item.dob);
      setDOBValue(item.dob);

      setJoinWork(item.joinwork);
      setUpgradeLevelDate(item.date_last_upgrad);
      setLevel(item.level);
      setSkill(item.skill_name);
      setStaffType(item.stafftype);
      setNoUpdate(item.no);
      setSelectedFile(null);
      setVisible(true); // just opent
      if (item.showadmin === 1) {
        console.log(" car data", item.showadmin);
        setEnable(true);
        setDisable(false);
      } else if (item.showadmin === 0) {
        console.log(" car data", item.showadmin);
        setEnable(false);
        setDisable(true);
      }
      if (item.car_data === 1) {
        console.log(" car data", item.car_data);
        setEnable_Car(true);
        setDisable_Car(false);
      } else if (item.car_data === 0) {
        console.log(" car data", item.car_data);
        setEnable_Car(false);
        setDisable_Car(true);
      }
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };

  const onclickDeleteNo = () => {
    setvisabledelete(false);
  };
  const onClickDeleteYes = async () => {
    console.log("ID delete is : " + id);
    const res = await request("user/" + id, "delete", {
      id,
      imageDelete,
      no_update,
    });
    if (res) {
      setvisabledelete(false);
      setLoadingSpin(true);
      await new Promise((resolve) => setTimeout(resolve, 400));
      openNotification("success", "Success", "Remove Success!");
      console.log("file name : " + imageDelete);
      getList(objFilter);
    } else {
      alert("Error!");
    }

    setLoadingSpin(false);
  };

  const buttonStyle = {
    fontFamily: "Content ,sans-serif", // Replace 'YourFontFamily' with your desired font family
  };
  const styleTageStatus = {
    fontSize: "14px",
    height: "30px",
    borderRadius: "30px",
    paddingTop: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
  };

  const getStatusContent = (status) => {
    switch (status) {
      case "ធ្វើការ":
        return (
          <Button
            className="styleTageStatus"
            type="primary"
            ghost
            shape="round"
            icon={<CheckCircleOutlined />}
          >
            {" "}
            ធ្វើការ{" "}
          </Button>
        );
      case "ព្យួរ":
        return (
          <Button
            className="styleTageStatus"
            type="primary"
            danger
            ghost
            shape="round"
            icon={<ClockCircleOutlined />}
          >
            {" "}
            ព្យួរ{" "}
          </Button>
        );
      case "ឈប់":
        return (
          <Button
            className="styleTageStatus"
            type="primary"
            danger
            shape="round"
            icon={<CloseOutlined />}
          >
            {" "}
            ឈប់{" "}
          </Button>
        );
      default:
        return null;
    }
  };
  const getGenderContet = (gender) => {
    switch (gender) {
      case "ស្រី":
        return (
          <Button
            className="styleTageStatus"
            style={{ height: "30px" }}
            type="primary"
            shape="round"
            icon={<WomanOutlined />}
          >
            {" "}
            ស្រី{" "}
          </Button>
        );

      case "ប្រុស":
        return (
          <Button
            className="styleTageStatus"
            style={{ height: "30px" }}
            type="primary"
            shape="round"
            ghost
            icon={<ManOutlined />}
          >
            {" "}
            ប្រុស{" "}
          </Button>
        );
      default:
        return null;
    }
  };
  const getProviderContet = (provider) => {
    switch (provider) {
      case "Admin":
        return (
          <Tag
            icon={<UsergroupAddOutlined />}
            style={styleTageStatus}
            color="green"
          >
            Admin
          </Tag>
        );

      case "User":
        return (
          <Tag
            icon={<UserDeleteOutlined />}
            style={styleTageStatus}
            color="gold"
          >
            User
          </Tag>
        );

      case "Leader":
        return (
          <Tag
            icon={<UsergroupAddOutlined />}
            style={styleTageStatus}
            color="green"
          >
            Leader
          </Tag>
        );

      default:
        return (
          <Tag
            icon={<UserDeleteOutlined />}
            style={styleTageStatus}
            color="gold"
          >
            {provider}
          </Tag>
        );
    }
  };

  //-----------------
  //-----------search ........
  const OnSearch = (value) => {
    getList(objFilter);
  };

  //------------ upload file -----------
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageSave, setImagesave] = useState(null);
  const [disablePrint, setDisablePrint] = useState(false);
  const inputRef = useRef(null);
  const [image, setImage] = useState(null);
  const handleFileChange = (e) => {
    try {
      var file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
          setModalOpenCropImage(true); //
          console.log("Image loaded");
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCroppedImage = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      const croppedCanvas = cropper.getCroppedCanvas();

      if (!croppedCanvas) {
        console.error(
          "Cropped canvas returned null. Check image source and CORS."
        );
        return;
      }

      // Compress image using the quality parameter
      croppedCanvas.toBlob(
        (blob) => {
          if (blob) {
            const compressedImageUrl = URL.createObjectURL(blob);
            setImagesave(compressedImageUrl); // Save compressed image URL
            setImagePreview(compressedImageUrl); // Preview image
            // setEmptyUser(compressedImageUrl); // Update other states
            console.log("Compressed image Blob URL:", compressedImageUrl);

            // Close the modal
            setModalOpenCropImage(false);
          }
        },
        "image/jpeg", // Format (use 'image/png' if transparency is needed)
        0.5 // Compression quality (adjust between 0.5 - 0.9 for optimal size/quality balance)
      );
    }
  };
  const onChangePostionsData = (value) => {
    setPositionID(value);
    setSelectedPosition(value);
    console.log("value = ", selectedPosition);
  };
  const onCancel = () => {
    setVisible(false);
  };
  const onCancelDelete = () => {
    setvisabledelete(false);
  };
  // Step 1: State for Checkbox Status
  const [checkboxes, setCheckboxes] = useState({});

  // Step 2: Handle Checkbox Changes
  const handleCheckboxChange = (id) => {
    setCheckboxes({
      ...checkboxes,
      [id]: !checkboxes[id], // Toggle the checkbox status
    });
  };
  useEffect(() => {
    const checkedIds = Object.keys(checkboxes).filter((id) => checkboxes[id]);
    setDisablePrint(checkedIds.length != 0); // If checkedIds is empty, setDisablePrint to false
    console.log(checkedIds);
  }, [checkboxes]);

  // Handle Checkbox Change for "Select All" Checkbox

  const onOpenModal = async () => {
    // onCloseModal();
    setVisible(true);
    setId("");
    setPhone("");
    setIDNumber("");
    setStaffnameEnglish("");
    setStaffname("");
    setRoleID("");
    setDepartment("");
    setSelectedPosition("");
    setFrontname("");
    setStatus("");
    setGender("");
    setDOB(dayjs("1990-01-01").format("YYYY-MM-DD"));
    setSkill(null);
    setLevel(null);
    setJoinWork(dayjs("1990-01-01").format("YYYY-MM-DD"));
    setStaffType(null);
    setUpgradeLevelDate(dayjs("1990-01-01").format("YYYY-MM-DD"));
    setImagesave(null);
    setSelectedFile(null);
    setImagePreview(null);
    setEnable(false);
    setDisable(true);
    setEnable_Car(false);
    setDisable_Car(true);
  };
  const onPrintCardFrontSite = async () => {
    const id = [getProfileID.user_id];

    try {
      setLoadingSpin(true);
      const res = await request("usercard/" + id.join(","), "get", {});
      setListCard(res.datacard);

      // Additional logic to handle the response as needed
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle error, show an error message, etc.
    } finally {
      setLoadingSpin(false);
      setShouldPrintFront(true);
    }
  };
  const onPrintCardBackSite = async () => {
    const id = [getProfileID.user_id];
    try {
      setLoadingSpin(true);
      const res = await request("usercard/" + id.join(","), "get", {});
      setListCard(res.datacard);
      // Additional logic to handle the response as needed
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle error, show an error message, etc.
    } finally {
      setLoadingSpin(false);
      setShouldPrintBack(true);
    }
  };
  class MyComponentToPrintFrontSite extends React.Component {
    render() {
      const { listCard } = this.props;
      return (
        <div className="print-content front-paper">
          {listCard && listCard.length > 0 ? (
            listCard.map((item, index) => (
              <CardComponentFront
                key={index}
                className="front-paper"
                frontname={item.frontname}
                staffname={item.fullname}
                deparment={item.department_name}
                position={item.position_name}
                image={item.image}
                idnumber={item.idnumber}
                frontImage={frontImage}
                impage_profile={
                  item.image == "" ? "empty_user.png" : item.image
                }
              />
            ))
          ) : (
            <p>No data available</p>
          )}
        </div>
      );
    }
  }

  class MyComponentToPrintBackSite extends React.Component {
    render() {
      const { listCard } = this.props;
      return (
        <div className="print-content-card back-paper">
          {listCard && listCard.length > 0 ? (
            listCard.map((item, index) => (
              <CardComponentBack
                key={index}
                className="back-paper"
                staffname={item.fullname}
                deparment={item.department_name}
                position={item.position_name}
                image={item.image}
                backImage={backImage}
                profileqrcode={getProfileID.domain}
              />
            ))
          ) : (
            <p>No data available</p>
          )}
        </div>
      );
    }
  }

  const onCheckboxAll = () => {
    alert("hello");
  };
  const handleDateChange_DOB = (date) => {
    setDOB(dayjs(date).format("YYYY-MM-DD"));
    // console.log("day is change : ", dayjs(date));
  };

  const handleDateChange_JoinWork = (date) => {
    setJoinWork(dayjs(date));
  };

  const handleDateChange_UpgradeLevel = (date) => {
    setUpgradeLevelDate(dayjs(date));
  };

  const onChangeSkill = (value) => {
    setSkillID(value);
    setSkill(value);
  };

  const onChangeLevel = (value) => {
    setLevel(value);
  };
  const dateFormat = "YYYY-MM-DD";
  const onChangeStafftype = (value) => {
    setStaffType(value);
  };

  const handleCheckboxChange_Enable = (e) => {
    setEnable(e.target.checked);
    setDisable(false);
  };
  const handleCheckboxChange_Diable = (e) => {
    setDisable(e.target.checked);
    setEnable(false);
  };
  const handleCheckboxChange_Enable_Car = (e) => {
    setEnable_Car(e.target.checked);
    setDisable_Car(false);
  };
  const handleCheckboxChange_Diable_Car = (e) => {
    setDisable_Car(e.target.checked);
    setEnable_Car(false);
  };
  //--------------------------------------------

  const onClickMoreData = async (item) => {
    setIDNumber(item.idnumber);
    console.log("ID statff:", idnumber);
    if (!visibleDraw) {
      setVisableDraw(true);
    }
  };

  const onClose = () => {
    setVisableDraw(false);
    setIDNumber("");
  };
  const onChangePageNumber = (page) => {
    setCurrentPage(page);
    setLoading(true);
    var objTmp = {
      ...objFilter,
      page: page,
    };
    setObjFilter(objTmp);
    getList(objTmp);
  };

  const getImageLinkOrganization = async () => {
    const param = new URLSearchParams({ org_code: getProfileID.org_code });
    try {
      const res = await request(
        "settingorgidcard?" + param.toString(),
        "get",
        {}
      );
      if (res && res.list_api) {
        const frontSide = res.list_api.find(
          (item) => item.param === "fs-idcard"
        );
        const backSide = res.list_api.find(
          (item) => item.param === "bs-idcard"
        );

        if (frontSide) setFrontImage(frontSide.imagelink);
        if (backSide) setBackImage(backSide.imagelink);
      }
    } catch (error) {
      console.error("Failed to fetch organization images:", error);
    }
  };
  const getRandomGradient = () => {
    const colors = ["#2471a3", "#145a32", "#FF99AC"];
    const color1 = colors[Math.floor(Math.random() * colors.length)];
    const color2 = colors[Math.floor(Math.random() * colors.length)];
    return `linear-gradient(135deg, ${color1}, ${color2})`;
  };
  const handleKeyPress_phone = (e) => {
    const charCode = e.charCode || e.keyCode;
    const inputValue = e.target.value;

    // Allow only numbers (48–57 are char codes for 0–9)
    if (charCode < 48 || charCode > 57 || inputValue.length >= 15) {
      e.preventDefault();
    }
  };
  const handleKeyPress_idNumber = (e) => {
    const charCode = e.charCode || e.keyCode;
    const inputValue = e.target.value;

    // Allow only numbers (48–57 are char codes for 0–9)
    if (charCode < 48 || charCode > 57 || inputValue.length >= 15) {
      e.preventDefault();
    }
  };
  const handleKeyPress_keyenglish = (e) => {
    const charCode = e.charCode || e.keyCode;
    // Allow only English letters (A-Z, a-z) and space (charCode 32)
    if (
      (charCode < 65 || charCode > 90) && // A-Z
      (charCode < 97 || charCode > 122) && // a-z
      charCode !== 32 // space
    ) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <div>
        <UserDetail
          visible={visibleDraw}
          onClose={onClose}
          idNumber={idnumber}
        />
        {/* when click save or update data  */}
        <div className="sprinSave-container">
          {/* Your other components */}
          {loadingSpin && <div className="sprinSave">{GetLoading()}</div>}
        </div>

        {/* -----------end save or updagte  */}

        <div style={{ paddingBottom: 10, display: "none" }}>
          <TreeSelect
            className="styleTageStatusForm"
            style={{
              width: "20%",
            }}
            value={treeValueSelect}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            treeData={treeData}
            placeholder="Please select"
            treeDefaultExpandAll
            onChange={onChange}
          />
        </div>

        <div>
          {loading ? (
            <div className="sprinSave">{GetLoading()}</div>
          ) : (
            <div>
              {list.map((item, index) => (
                <div
                  style={
                    {
                      // marginBottom: "20px",
                      // padding: "15px",
                      // fontFamily: "Content",
                      // borderRadius: "10px",
                      // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    }
                  }
                  key={index}
                >
                  <div
                    data-aos="zoom-in"
                    style={{ marginTop: "5px", textAlign: "center" }}
                  >
                    <div
                      style={{
                        fontFamily: "Content",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        className="ionavatar"
                        src={
                          linkPath +
                          encodeURIComponent(
                            item.image == "" ? "empty_user.png" : item.image
                          )
                        }
                        size={124}
                      />
                      <span
                        style={{
                          paddingTop: "10px",
                          fontWeight: "bold",
                          color: "#117a65",
                        }}
                      >
                        {item.frontname} {item.fullname} | {item.position_name}{" "}
                        | {item.stafftype}
                        <br /> អត្ថលេខមន្ត្រី : {item.idnumber} | Tell:{" "}
                        {item.username}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Stack
                            gap={1}
                            direction="horizontal"
                            style={{ marginTop: "5px", marginBottom: "10px" }}
                          >
                            <Button
                              type="primary"
                              icon={<FaRegEye />}
                              onClick={() => onClickMoreData(item)}
                            />
                            <Button
                              type="primary"
                              icon={<MdOutlineEdit />}
                              onClick={() => onClickEdit(item)}
                            />
                          </Stack>
                        </div>
                      </span>
                    </div>
                    <div className="custom-screen">
                      <div>
                        <MyComponentToPrintFrontSite
                          ref={componentRefFront}
                          style={{ display: "none" }}
                          listCard={listCard}
                        />
                        <Button
                          style={buttonStyle}
                          type="primary"
                          styles={{ backgroundColor: "black" }}
                          icon={<IdcardOutlined />}
                          onClick={onPrintCardFrontSite}
                        >
                          ព្រីនកាតបុគ្គលិក
                        </Button>

                        <MyComponentToPrintBackSite
                          ref={componentRefBack}
                          style={{ display: "none" }}
                          listCard={listCard}
                        />
                        <Button
                          style={buttonStyle}
                          onClick={onPrintCardBackSite}
                          type="primary"
                          icon={<IdcardOutlined />}
                        >
                          ព្រីនកាតបុគ្គលិក(ខាងក្រោយ)
                        </Button>
                        <Button
                          className="buttonstylekhmer"
                          type="primary"
                          icon={
                            <IoDocumentTextOutline
                              style={{ width: 20, height: 20 }}
                            />
                          }
                        >
                          ព្រីនប្រវត្តរូបសង្ខេប
                        </Button>
                      </div>
                    </div>

                    <div className="Leave-Setting-Grid">
                      {listCount &&
                        listCount.map((item) => {
                          const percentage =
                            (item.total / item.target_count) * 100;

                          // Determine tint color based on index
                          const tintColor =
                            index === 0
                              ? "#5D5FEE"
                              : index === 1
                              ? "#27AE60"
                              : index === 2
                              ? "#F39C12"
                              : "#9B59B6";

                          return (
                            <div>
                              <div
                                key={item.id}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  background: getRandomGradient(),
                                  margin: 5,
                                  padding: 10,
                                  fontFamily: "Content",
                                  borderRadius: 15,
                                  color: "#fff",
                                  fontWeight: "bold",
                                  boxShadow: "0 1px 8px rgba(0, 0, 0, 0.3)",
                                }}
                              >
                                <div
                                  style={{
                                    height: 100,
                                    width: 100,
                                    marginBottom: 10,
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <CircularProgressbar
                                    value={percentage}
                                    text={`${Math.round(percentage)}%`}
                                    styles={buildStyles({
                                      textSize: "20px",

                                      textColor: "white",
                                      pathColor: tintColor,
                                      trailColor: "#E0E0E0",
                                      strokeLinecap: "round",
                                    })}
                                  />
                                </div>
                                {item.type_name}
                                <div
                                  style={{
                                    backgroundColor: "white",
                                    paddingButtom: 5,
                                    paddingTop: 5,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    borderRadius: 20,
                                  }}
                                >
                                  <span style={{ color: "black" }}>
                                    {item.total === null ? 0 : item.total} /{" "}
                                    {item.target_count} ថ្ងៃ
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {/* បង្កើតបុគ្គលិកថ្មី */}
        <Modal
          width={900} // Responsive width
          title={id == "" ? "New" : "Update"}
          open={visible}
          onCancel={onCancel}
          footer={[
            <div>
              <Space>
                <Button
                  style={{ width: 100 }}
                  onClick={onCloseModal}
                  variant="secondary"
                >
                  Cancel
                </Button>
                <Button
                  style={{ width: 100 }}
                  type="primary"
                  onClick={onSave}
                  variant="primary"
                >
                  {id == "" ? "Save" : "Update"}
                </Button>
              </Space>
            </div>,
          ]}
          style={buttonStyle}
        >
          <div class="model-setting">
            <div>
              <div>
                <div style={{ position: "relative" }}>
                  {/* Display the selected image preview */}
                  <div style={{ paddingLeft: 50, paddingBottom: 10 }}>
                    <Avatar
                      className="ionavatar"
                      src={imagePreview}
                      alt="Preview"
                      style={{ width: 120, height: 120 }} // Adjust size as needed
                    />
                  </div>

                  {/* Camera icon positioned relative to the profile image */}
                  <div style={{ position: "absolute", top: 100, left: 130 }}>
                    {" "}
                    {/* Adjust these values */}
                    <label htmlFor="fileInput" className="custom-file-label">
                      <Avatar
                        icon={<CiCamera />}
                        style={{
                          backgroundColor: "#4fa94d",
                          cursor: "pointer",
                        }}
                      />
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      ref={inputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      defaultValue=""
                    />
                  </div>
                </div>

                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  លេខទូស័ព្ទ / PhoneNumber
                </p>
                <Input
                  onKeyPress={handleKeyPress_phone}
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    height: 40,
                    color: "gray",
                  }}
                  //  className="styleTageStatusForm"
                  placeholder="Input Staff Phone Number"
                  onChange={onChangePhone}
                  value={phone}
                />
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  លេខមន្ត្រីរាជការ / IDNumber
                </p>
                <Input
                  onKeyPress={handleKeyPress_idNumber}
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    height: 40,
                    color: "gray",
                  }}
                  placeholder="Input ID Number"
                  onChange={onChangeIDNumber}
                  value={idnumber}
                />
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ឈ្មោះបុគ្គលិក / staff name
                </p>
                <Input
                  className="styleTageStatusForm"
                  placeholder="Input Staff Name"
                  style={{
                    width: 250,
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                  onChange={onChangeStaffname}
                  value={staffname}
                />
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ឈ្មោះ ឡាតាំង / English name
                </p>
                <Input
                  type="text"
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    height: 40,
                    color: "gray",
                  }}
                  placeholder="Input Staff Name"
                  onChange={onChangeEnglishName}
                  value={staffname_eng}
                  onKeyPress={handleKeyPress_keyenglish}
                />
              </div>
            </div>

            <div style={{ paddingLeft: 25 }}>
              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ភេទ / Gender
                </p>

                <Select
                  className="styleTageStatusForm"
                  placeholder="Select Gender"
                  onChange={onChangeGender}
                  value={gender}
                  style={{ width: 250 }}
                  allowClear
                >
                  <Option className="styleTageStatusForm" value="ស្រី">
                    ស្រី
                  </Option>
                  <Option className="styleTageStatusForm" value="ប្រុស">
                    ប្រុស
                  </Option>
                </Select>
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  សិទ្ធ / Provider
                </p>
                <Select
                  disabled={true}
                  className="styleTageStatusForm"
                  onChange={onChangeProvider}
                  value={roleid}
                  style={{ width: 250 }}
                  allowClear
                >
                  {datarole.map((item) => (
                    <Option
                      className="styleTageStatusForm"
                      key={item.role_id}
                      value={item.role_id}
                    >
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ផ្នែក / Department
                </p>
                <Select
                  disabled={true}
                  className="styleTageStatusForm"
                  placeholder="Select an Deparment"
                  style={{ width: 250 }}
                  onChange={onChangeDepartment}
                  value={department}
                >
                  {departmentlist.map((option) => (
                    <Option
                      className="styleTageStatusForm"
                      key={option.code}
                      value={option.department_name}
                    >
                      {option.department_name}{" "}
                      {/* Replace with the field from your MySQL table */}
                    </Option>
                  ))}
                </Select>
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  តួនារទី / Position
                </p>

                <Select
                  disabled={true}
                  className="styleTageStatusForm"
                  id="position"
                  value={selectedPosition}
                  style={{ width: 250 }}
                  onChange={onChangePostionsData}
                >
                  <option value="">Select Position</option>
                  {positionslist !== null &&
                    positionslist.map((position) => (
                      <option
                        className="styleTageStatusForm"
                        key={position?.position_kh || "default"}
                        value={position?.position_kh || "default"}
                      >
                        {position?.position_kh || "Default Position"}
                      </option>
                    ))}
                </Select>
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ឈ្មោះ ខាងមុខ
                </p>
                <Select
                  className="styleTageStatusForm"
                  placeholder="Select"
                  style={{ width: 250 }}
                  onChange={onChangeFrontName}
                  value={frontname}
                >
                  {listFrontName.map((option) => (
                    <Option
                      className="styleTageStatusForm"
                      key={option.id}
                      value={option.frontname_kh}
                    >
                      {option.frontname_kh}{" "}
                      {/* Replace with the field from your MySQL table */}
                    </Option>
                  ))}
                </Select>
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ស្ថានភាពការងារ / Status
                </p>

                <Select
                  disabled={true}
                  className="styleTageStatusForm"
                  onChange={onChangeStatus}
                  value={status}
                  style={{ width: 250 }}
                  allowClear
                >
                  <Option className="styleTageStatusForm" value="ធ្វើការ">
                    ធ្វើការ
                  </Option>
                  <Option className="styleTageStatusForm" value="ព្យួរ">
                    ព្យួរ
                  </Option>
                  <Option className="styleTageStatusForm" value="បណ្តុះបណ្តាល">
                    បណ្តុះបណ្តាល
                  </Option>
                  <Option className="styleTageStatusForm" value="ឈប់">
                    ឈប់
                  </Option>
                </Select>
              </div>

              <div
                style={addvalue ? { display: "block" } : { display: "none" }}
              >
                <p
                  style={{
                    color: "red",
                    paddingTop: 5,
                    fontFamily: "Content ,sans-serif",
                  }}
                >
                  អនុញ្ញាតច្បាប់ / Approved Leave
                </p>

                <Tag color="magenta">
                  <div style={{ padding: 5 }}>
                    <Checkbox
                      disabled={true}
                      checked={enable}
                      onChange={handleCheckboxChange_Enable}
                    >
                      Enable
                    </Checkbox>
                    <Checkbox
                      disabled={true}
                      style={{ color: "red" }}
                      checked={disable}
                      onChange={handleCheckboxChange_Diable}
                    >
                      Disable
                    </Checkbox>
                  </div>
                </Tag>
              </div>
            </div>

            <div style={{ paddingLeft: 25 }}>
              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ថ្ងៃខែកំណើត / DateOfBirth
                </p>
                <div>
                  <DatePicker
                    name={dob}
                    value={dayjs(dob)}
                    format="DD/MM/YYYY"
                    onChange={handleDateChange_DOB}
                    className="styleDatePicker"
                    allowClear
                  />
                </div>
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ថ្ងៃចូលធ្វើការ / Join Work
                </p>
                <div>
                  <DatePicker
                    disabled={true}
                    name={joinwork}
                    value={dayjs(joinwork)}
                    format="DD/MM/YYYY"
                    onChange={handleDateChange_JoinWork}
                    className="styleDatePicker"
                    allowClear
                  />
                </div>
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ជំនាញ / Skill
                </p>
                <Select
                  disabled={true}
                  className="styleTageStatusForm"
                  value={skill}
                  style={{ width: 250 }}
                  onChange={onChangeSkill}
                >
                  <option value="">ជ្រើសរើស ជំនាញ</option>
                  {ListSkillAPI !== null &&
                    ListSkillAPI.map((skill_item) => (
                      <option
                        className="styleTageStatusForm"
                        key={skill_item.skill_id}
                        value={skill_item.skill_id}
                      >
                        {skill_item.skill_kh}
                      </option>
                    ))}
                </Select>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  កាំប្រាក់ / Level
                </p>
                <Select
                  disabled={true}
                  className="styleTageStatusForm"
                  value={level}
                  style={{ width: 250 }}
                  onChange={onChangeLevel}
                >
                  <option value="">ជ្រើសរើស កាំប្រាក់</option>
                  {ListLevelAPI !== null &&
                    ListLevelAPI.map((level_item) => (
                      <option
                        className="styleTageStatusForm"
                        key={level_item.id}
                        value={level_item.level_kh}
                      >
                        {level_item.level_kh}
                      </option>
                    ))}
                </Select>
              </div>
              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ថ្ងៃចុងក្រោយឡើងកាំប្រាក់
                </p>
                <div>
                  <DatePicker
                    disabled={true}
                    name={updateleveldate}
                    value={dayjs(updateleveldate)}
                    format="DD/MM/YYYY"
                    onChange={handleDateChange_UpgradeLevel}
                    className="styleDatePicker"
                    allowClear
                  />
                </div>
              </div>

              <div>
                <p style={{ margin: 5, color: "darkgray", paddingTop: 10 }}>
                  ប្រភេទបុគ្គលិក / Staff Type
                </p>
                <Select
                  disabled={true}
                  name={stafftype}
                  className="styleTageStatusForm"
                  onChange={onChangeStafftype}
                  value={stafftype}
                  style={{ width: 250 }}
                  allowClear
                >
                  <Option className="styleTageStatusForm" value="រដ្ឋ">
                    រដ្ឋ
                  </Option>
                  <Option className="styleTageStatusForm" value="កិច្ចសន្យា">
                    កិច្ចសន្យា
                  </Option>
                  <Option className="styleTageStatusForm" value="ជួល">
                    ជួល
                  </Option>
                  <Option className="styleTageStatusForm" value="ផ្សេងៗ">
                    ផ្សេងៗ
                  </Option>
                </Select>
              </div>
              <div
                style={addvalue ? { display: "block" } : { display: "none" }}
              >
                <p
                  style={{
                    color: "red",
                    paddingTop: 5,
                    fontFamily: "Content ,sans-serif",
                  }}
                >
                  បញ្ចូលទិន្នន័យបញ្ជូនឡានជំងឺ ៖
                </p>

                <Tag color="magenta">
                  <div style={{ padding: 5 }}>
                    <Checkbox
                      disabled={true}
                      checked={enable_car}
                      onChange={handleCheckboxChange_Enable_Car}
                    >
                      Enable
                    </Checkbox>
                    <Checkbox
                      disabled={true}
                      style={{ color: "red" }}
                      checked={disable_car}
                      onChange={handleCheckboxChange_Diable_Car}
                    >
                      Disable
                    </Checkbox>
                  </div>
                </Tag>
              </div>
            </div>
          </div>
        </Modal>
        {/* លុប​បុគ្គលិកចេញពីប្រព័ន្ធ */}
        <Modal
          title="លុបបុគ្គលិក "
          style={buttonStyle}
          open={visabledelete}
          onCancel={onCancelDelete}
          footer={[
            <div>
              <Space>
                <Button onClick={onclickDeleteNo}>No</Button>
                <Button onClick={() => onClickDeleteYes()} type="primary">
                  Yes
                </Button>
              </Space>
            </div>,
          ]}
        >
          <p style={buttonStyle}>
            <p style={{ color: "red" }}>តើអ្នកចង់លុប បុគ្គលិកឈ្មោះ </p>
            {staffname} ?
          </p>
        </Modal>

        <Modal
          visible={isModalOpenCropImage}
          // onRequestClose={() => setIsModalOpen(false)}
          title="Crop Image"
          onOk={getCroppedImage}
          onCancel={() => setModalOpenCropImage(false)}
          okText="Crop"
          cancelText="Cancel"
        >
          {image && (
            <Cropper
              crossOrigin="anonymous"
              src={image}
              style={{ height: 400, width: "100%" }}
              initialAspectRatio={1}
              aspectRatio={1}
              guides={false}
              ref={cropperRef}
              key={image} // Add this line
              viewMode={1}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

export default ProfileUser;
