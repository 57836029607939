import React, { useState, useEffect } from "react";
import "../../styles/Home-module.css";
import "./Model-Setting-Grid.css";
import {
  formateDateClient,
  formattedDate,
  linkPath,
  linkPathReport,
} from "../../share/helper";
import { getCurrentUser, getPermission } from "../../share/getProfile";
import { QRCode } from "antd";
import { BiLogoTelegram } from "react-icons/bi";
import { GrLocation } from "react-icons/gr";
import { FooterPagePrint } from "./FooterPagePrint";
const moment = require("moment");
require("@thyrith/momentkh")(moment);

const ComponentToPrint = ({
  reason,
  node,
  date_start,
  date_end,
  support_by,
  car_using,
  liststaff,
  Org_ImagePath,
  name_signature,
  postion_signature,
  org_cover_by,
  date_document,
  date_signature,
  do_in_provinceName,
  org_type_string,
  location_contact,
  org_type_code,
  location,
}) => {
  let api_date_document = moment(date_document, "YYYY-MM-DD");
  let api_date_signature = moment(date_signature, "YYYY-MM-DD");
  let khmerDateDocument = api_date_document.toLunarDate();
  let khmerDateSingnature = api_date_signature.toLunarDate();

  const [finalTop, setFinalTop] = useState(0); // State for the final `top` value
  const OrgProfile = getCurrentUser();
  const topMainMargine = 400;
  const textX = 130; // Adjust X coordinate as needed
  const textY = 560; // Adjust Y coordinate as needed
  // get type string for organization code

  // Helper to calculate the image path
  const printpath = (value) => {
    return value <= 3
      ? linkPathReport + "mission.jpg"
      : linkPathReport + "mission.jpg";
  };
  const [stringType, setStringType] = useState(null); // State for

  // Helper to convert to Khmer numerals
  const toKhmerNumeral = (number) => {
    const khmerDigits = ["០", "១", "២", "៣", "៤", "៥", "៦", "៧", "៨", "៩"];
    return String(number)
      .split("")
      .map((digit) => khmerDigits[digit])
      .join("");
  };
  const calculateFinalTop = () => {
    if (liststaff && liststaff.length > 0) {
      return topMainMargine + (liststaff.length - 1) * 22 + 30;
    }
    return 0;
  };

  const P_finalTop = calculateFinalTop(); // Calculate directly
  useEffect(() => {
    if (liststaff && liststaff.length > 0) {
      let P_stringType = null; // Default state
      console.log("code of org : ", OrgProfile.org_type);
      switch (OrgProfile.org_type) {
        case 1:
          P_stringType = null; // For organization PHD
          break;
        case 2:
        case 3:
          P_stringType = `លោកប្រធានមន្ទីរសុខាភិបាលរដ្ឋបាលខេត្ត ${OrgProfile.province_name}`;
          break;
        case 4:
        case 5:
        case 6:
          P_stringType = `លោកប្រធានការិយាល័យសុខាភិបាលស្រុកប្រតិបត្តិ ${OrgProfile.province_name}`;
          break;
        default:
          P_stringType = ""; // Handle unexpected org_type_code
          break;
      }
      console.log(" string type", P_stringType);
      setStringType(P_stringType);

      console.log("Calculating top value...");
      const calculatedTop = 370 + (liststaff.length - 1) * 22;
      setFinalTop(calculatedTop + 100);
      console.log("Final top value:", calculatedTop);
    } else {
      console.log("liststaff is empty or undefined.");
      setFinalTop(0);
    }
  }, [liststaff, OrgProfile.org_type]);
  const top =
    liststaff && Array.isArray(liststaff) && liststaff.length <= 1
      ? P_finalTop + 180
      : liststaff && Array.isArray(liststaff) && liststaff.length <= 2
      ? P_finalTop + 180
      : liststaff && Array.isArray(liststaff) && liststaff.length <= 3
      ? P_finalTop + 180
      : liststaff && Array.isArray(liststaff) && liststaff.length <= 4
      ? P_finalTop + 130
      : liststaff && Array.isArray(liststaff) && liststaff.length <= 5
      ? P_finalTop + 80
      : liststaff && Array.isArray(liststaff) && liststaff.length <= 6
      ? P_finalTop + 40
      : liststaff && Array.isArray(liststaff) && liststaff.length <= 7
      ? P_finalTop + 15
      : P_finalTop;

  const Nodes = () => {
    return (
      <div>
        <div className="text-overlay" style={{ top: P_finalTop, left: 350 }}>
          <span className="text-on-image-name_letters_mission">
            សរុប {liststaff.length} នាក់
          </span>
        </div>
        <div
          className="text-overlay"
          style={{
            top: top,
            left: textX,
            right: 20,
          }}
        >
          <br />
          <br />
          <table
            className="text-on-image-name_letters_mission"
            style={{ borderCollapse: "collapse" }}
          >
            {OrgProfile.org_type != 1 && (
              <tr>
                <td
                  style={{
                    width: "80px",
                    verticalAlign: "top",
                    lineHeight: "2",
                  }}
                  className="text-on-image-name_rightmanager_mission"
                >
                  យោង​ :
                </td>
                <td style={{ lineHeight: "2" }}> {node} </td>
              </tr>
            )}
            <tr>
              <td
                style={{
                  width: "80px",
                  lineHeight: "2",
                  verticalAlign: "top", // Align content at the top
                }}
                className="text-on-image-name_rightmanager_mission"
              >
                <div>
                  <span> កម្មវត្ថុ :</span>
                </div>
              </td>
              <td style={{ verticalAlign: "top", lineHeight: "2" }}>
                {reason}
                <br />
              </td>
            </tr>
            <tr>
              <td style={{ width: "80px", lineHeight: "2" }}>ចេញដំណើរ :</td>
              <td style={{ lineHeight: "2" }}> {date_start} </td>
            </tr>
            <tr>
              <td style={{ width: "80px", lineHeight: "2" }}>ផុតកំណត់ :</td>
              <td style={{ lineHeight: "2" }}> {date_end} </td>
            </tr>
            <tr>
              <td style={{ width: "100px", lineHeight: "2" }}>មធ្យោបាយ :</td>
              <td style={{ lineHeight: "2" }}> {car_using} </td>
            </tr>
            <tr>
              <td style={{ width: "100px", lineHeight: "2" }}>ឧបត្ថមដោយ :</td>
              <td style={{ lineHeight: "2" }}> {support_by} </td>
            </tr>
            <tr>
              <td colSpan={2}>
                {OrgProfile.org_type !== 1 && (
                  <span style={{ marginBottom: 10 }}>
                    សេចក្តីដូចបានជំរាបជូនតាមកម្មវត្ថុ និងយោងខាងលើ សូមលោកប្រធាន
                    មេត្តាសម្រេចតាមការគួរ​។
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{
                  alignItems: "start",
                }}
              >
                <div
                  style={{
                    width: "650px",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "end",
                  }}
                >
                  <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* <span>បានឃើញ និង...................</span>
                    <span></span>

                    <span>
                      ................ថ្ងៃទី........ខែ.......ឆ្នាំ២០២.....
                    </span> */}
                    <span className="text-on-image-name_rightmanager_mission">
                      {postion_signature}
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div
          className="text-overlay"
          style={{
            top: OrgProfile.org_type === 1 ? 310 : 350,
            left: 125,
          }}
        >
          <table className="text-on-image-name_letters_mission">
            {OrgProfile.org_type === 1 && (
              <tr>
                <td
                  colSpan={2} // ✅ ប្រើ colSpan ខុសពី columnSpan
                >
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; យោង​{node} និងយោងចំណារ ឯកភាព
                    របស់ {postion_signature} ចុះថ្ងៃទី......ខែ.....ឆ្នាំ២០២....
                    ​​មន្ត្រីសុខាភិបាល នៃរដ្ឋបាលខេត្ត
                    ដូចមានរាយនាមខាងក្រោមនេះត្រូវបានចាត់តាំងអោយ ទៅបំពេញបេសកកម្មនៅ
                    ៖ <span style={{ fontWeight: "bold" }}>{location}</span>។
                  </span>
                </td>
              </tr>
            )}
            {OrgProfile.org_type !== 1 && (
              <tr>
                <td
                  style={{ width: "80px", verticalAlign: "top" }}
                  className="text-on-image-name_rightmanager_mission"
                >
                  គោរពជូន :
                </td>
                <td> សំណើរសុំឧទ្ទេសនាមជូនមន្ត្រី ៖</td>
              </tr>
            )}
          </table>
        </div>
      </div>
    );
  };

  useEffect(() => {}, [OrgProfile.org_type]);

  const Header = () => {
    return (
      <div className="text-on-image-name_rightmanager_mission">
        <div
          className="text-overlay"
          style={{
            top: 30,
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="text-on-image-name_rightmanager_mission">
            <span>ព្រះរាជាណាចក្រកម្ពុជា</span> <br />
            <span>ជាតិ សាសនា ព្រះមហាក្សត្រ</span>
            <br />
            <img
              src={linkPath + "tacteing_font.jpg"}
              alt="tacteing font"
              style={{ width: "20%" }}
            />
          </div>
        </div>
        <div
          className="text-overlay"
          style={{
            marginLeft: 50,
            top: 80,
            display: "flex",
            width: "100%",
            alignItems: "start",
          }}
        >
          <div>
            <img
              src={Org_ImagePath}
              alt="Organization Logo"
              style={{ width: "70px", height: "70px", marginBottom: "5px" }}
            />
            <span>
              {OrgProfile.org_type <= 3 && (
                <span className="text-on-image-name_rightmanager_mission">
                  <br /> រដ្ឋបាលខេត្ត{do_in_provinceName} <br />
                </span>
              )}
              {OrgProfile.org_type >= 4 && (
                <span className="text-on-image-name_rightmanager_mission">
                  <br /> មន្ទីរសុខាភិបាលនៃរដ្ឋបាលខេត្ត <br />
                </span>
              )}
            </span>

            {OrgProfile.org_type != 1 && (
              <span className="text-on-image-name_rightmanager_mission">
                {OrgProfile.org_cover_by} <br />
              </span>
            )}
            <span className="text-on-image-name_rightmanager_mission">
              {OrgProfile.org_name_kh}
            </span>
            <br />
            <span className="text-on-image-name_letters_mission">
              លេខ៖...........
            </span>
          </div>
        </div>

        <div
          className="text-overlay"
          style={{
            // backgroundColor: "#a3e4d7",
            right: 50,
            top: 200,
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{ textAlign: "end" }}
            className="text-on-image-name_letters_mission"
          >
            <span>{khmerDateDocument}</span>
            <br />
            <span>
              {" "}
              ធ្វើនៅ{do_in_provinceName}{" "}
              {formattedDate(formateDateClient(date_document))}
            </span>
          </div>
        </div>

        <div
          className="text-overlay"
          style={{
            top: 270,
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {OrgProfile.org_type !== 1 && (
            <div className="text-on-image-name_rightmanager_mission">
              <span>{OrgProfile.org_name_kh}</span> <br />
              <span>គោរពជូន</span>
              <br />
              {/* <span>លោកប្រធាន{org_cover_by}</span> */}
              <span>{org_type_string}</span>
            </div>
          )}
          {OrgProfile.org_type === 1 && (
            <div className="text-on-image-name_rightmanager_mission">
              <span>លិខិតឧទ្ទេសនាម</span>
              <hr
                style={{
                  marginTop: 5,
                  height: 2,
                  backgroundColor: "black",
                  border: "none",
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  };
  // const Footer = () => (
  //   <div
  //     className="footer"
  //     style={{
  //       display: "flex",

  //       flexDirection: "row",
  //       justifyContent: "space-between",
  //       position: "absolute",
  //       bottom: "0",
  //       width: "100%",
  //       textAlign: "center",
  //       borderTop: "0.5px solid #e5e7e9",
  //       padding: "1px",
  //     }}
  //   >
  //     <div
  //       style={{
  //         marginLeft: "20px",
  //         padding: 5,
  //         width: "auto",
  //         display: "flex",
  //         flexDirection: "column",
  //         alignItems: "start",
  //         textAlign: "start",
  //       }}
  //     >
  //       <span class="khmer-text-footer">
  //         © {new Date().getFullYear()} .{OrgProfile.org_name_kh}
  //       </span>
  //       <div style={{ display: "flex", flexDirection: "row" }}>
  //         <GrLocation
  //           style={{ height: 13, width: 13, marginRight: 2, color: "#0667b1" }}
  //         />
  //         <span class="khmer-text-footer"> {location_contact}</span>
  //       </div>
  //       <div style={{ display: "flex", flexDirection: "row" }}>
  //         <BiLogoTelegram
  //           style={{ height: 13, width: 13, marginRight: 2, color: "#0667b1" }}
  //         />
  //         <span class="khmer-text-footer"> {OrgProfile.domain}</span>
  //       </div>
  //     </div>
  //     <div style={{ marginRight: "20px" }}>
  //       <QRCode
  //         color={"#0667b1"}
  //         bgColor="transparent"
  //         size={60}
  //         value={OrgProfile.domain}
  //       />
  //     </div>
  //   </div>
  // );
  return (
    <div style={{ marginLeft: 20 }}>
      <Header />

      {/* <img src={printpath(3)} alt="Leave Report" className="print-image" /> */}

      {/* Map over liststaff to render each staff's info */}
      {liststaff.map((staff, index) => (
        <div
          key={index}
          className="text-overlay"
          style={{
            top: topMainMargine + index * 22,
            left: 230,
            color: "#21618c",
          }}
        >
          <table className="text-on-image-name_rightmanager_mission">
            <tr>
              <td
                style={{
                  width: "300px",
                  textAlign: "start",
                  verticalAlign: "top",
                  borderBottom: "0.01em dotted #d7dbdd", // Dotted line styling
                  paddingBottom: "2px", // Adjust spacing if needed
                }}
              >
                {toKhmerNumeral(index + 1)}- {staff.frontname} {staff.fullname}
              </td>
              <td
                style={{
                  borderBottom: "0.01em dotted #d7dbdd",
                  paddingBottom: "2px",
                }}
              >
                {staff.position}
              </td>
            </tr>
          </table>
        </div>
      ))}
      <Nodes />
      {/* <Footer /> */}
      <FooterPagePrint location_contact={location_contact} />
    </div>
  );
};

export default ComponentToPrint;
