import React, { useEffect, useState, useRef } from "react";
import debounce from "lodash.debounce";
import {
  DownloadOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import {
  Modal,
  message,
  Upload,
  Button,
  Form,
  Input,
  Item,
  Pagination,
  Select,
  Space,
  Tag,
  Checkbox,
  Spin,
  Popconfirm,
  DatePicker,
  Divider,
  Alert,
  Dropdown,
  Menu,
  TimePicker,
} from "antd";
import "../../styles/Home-module.css";
import "./Model-Setting-Grid.css";
import { request } from "../../share/request";
import Table from "react-bootstrap/Table";
import { Stack } from "react-bootstrap";
import useOrganizationImage from "../../share/GetImageOrg";
import { openNotification } from "../../share/message";
import { Circles } from "react-loader-spinner";
import {
  getCurrentUser,
  getPermission,
  getProfileImageLinkPath,
} from "../../share/getProfile";
import {
  getLettersContent,
  getStatusContent,
  getTageStyle,
} from "../../share/getStatusContent";
import {
  formateDateClient,
  formateDateSystem,
  linkPath,
  linkPathPhotoDocument,
  toKhmerDay,
  toKhmerMonth,
  toKhmerYear,
} from "../../share/helper";
import { MdOutlineEdit, MdOutlineDelete } from "react-icons/md";
import { HiOutlineDownload } from "react-icons/hi";
import { FaRegEye } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";
import { BsDownload } from "react-icons/bs";
import ComponentToPrint from "../letter/PrintComponents";
import MyDatePicker from "../../share/MyDatePicker";
import { useReactToPrint } from "react-to-print";
import { PlusOutlined } from "@ant-design/icons";
import { BiAddToQueue, BiCheckDouble, BiDuplicate } from "react-icons/bi";
import { boxHeader, boxHeaderTagText } from "../../styles/boxShadow";
import dayjs from "dayjs";
import ComponentToPrint_Index1 from "./PrintComponentsIndex1";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ComponentToPrint_Index2 from "./PrintComponentsIndex2";
import { getMasterGroups } from "../../share/request";
import ComponentToPrint_Index2_in from "./PrintComponentsIndex2_in";
const { Option } = Select;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const LettersPage = () => {
  //--------------- master groups ------------------------------
  const [api_masterGroups_code, setMasterGroups] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const api_masterGroups = await getMasterGroups();

      if (api_masterGroups && Array.isArray(api_masterGroups)) {
        const orgCodes = api_masterGroups.map((group) => group.org_code);
        console.log("Extracted org_code list:", orgCodes);
        setMasterGroups(orgCodes);
      }
    };

    fetchData();
  }, []);
  //---------------  end master groups ------------------------------
  const getProfileOrg = getCurrentUser();
  const [objFilter, setObjFilter] = useState({
    page: 1,
    itemPerPage: 10,
    txtSearch: "",
    org_code: getProfileOrg.org_code,
  });
  //------------ get some data from profile organization --------------------
  const [org_type_code, setOrgTypeCode] = useState(getProfileOrg.org_type);
  const [location_contact, setlocation_contact] = useState(
    getProfileOrg.location_contact
  );
  const [org_cover_by, setorg_cover_by] = useState(getProfileOrg.org_cover_by);
  const [Org_ImagePath, SetOrgImagePath] = useState(
    "https://www.pngkit.com/png/full/981-9818240_your-logo-retulp-bus-stop.png"
  );
  const [Do_In_ProvinceName, setDo_in_province] = useState(
    getProfileOrg.province_name
  );
  //----------------
  const [totalCount, setTotalCount] = useState("");
  const [listdocument, setlistdocument] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [ListFinal, setListFinal] = useState([]);
  const [listJoiner, setListJoiner] = useState([]);
  const [listSignature, setListSignature] = useState([]);
  const [UserID, setUserID] = useState("");
  const [UserFile_ID, setUserFile_ID] = useState("");
  const [UserLetters_type, setUserLetters_Type] = useState("");
  const [UserLetters_FromTo, setUserLetters_FromTo] = useState("");
  const [UserLettter_number, setUserLettter_number] = useState("");
  const [UserLettter_Date, setUserLettter_Date] = useState(dayjs());
  const [UserLetters_Note, setUserLeters_Note] = useState();
  const [UserLettter_Description, setUserLettter_Description] = useState("");
  const [UserLetters_DateStart, setUserLetters_DateStart] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [UserLetters_DateEnd, setUserLetters_DateEnd] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const [UserLetters_DateDocument, setUserLetters_DateDocument] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [UserLetters_DateSignature, setUserLetters_DateSignature] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const [UserLetters_location, setUserLetters_location] = useState("");
  const [UserLetters_PrepareBy, setUserLetters_PrepareBy] = useState("");
  const [UserLetters_SupportBy, setUserLetters_SupportBy] = useState("");
  const [UserLetters_StaffCodeJoin, setUserLetters_StaffCodeJoin] = useState();
  const [searchText, setSearchText] = useState("");
  const [imageDeletePath, setImageDeletePath] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingSpin, setLoadingSpin] = useState(false);
  // get imag logo
  const [UserLetters_Clothing, setUserLetters_Clothing] = useState("សមរម្យ");

  // -----------------  permisstion page --------------------
  const userPermission = getPermission();
  const letterPageValue = userPermission.find(
    (permission) => permission.pagename === "letterspage"
  ) || { add: 0, edit: 0, deleted: 0, print: 0 };
  const {
    add: addvalue,
    edit: Editvalue,
    deleted: deletevalue,
    print: printValue,
  } = letterPageValue;
  // -----------------  end permisstion page --------------------
  const [previewFile, setPreviewFile] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handleCancel = () => setPreviewOpen(false);

  // print component
  const componentRef = useRef(null);
  const [itemPrint, setItemPrint] = useState(null);
  const [shouldPrint, setShouldPrint] = useState(false);
  const [itemPrint_index1, setItemPrint_index1] = useState(null);
  const [shouldPrint_index1, setShouldPrint_index1] = useState(false);

  const [dataFetched, setDataFetched] = useState(false);
  const [dataFetched_index1, setDataFetched_index1] = useState(false);
  //-------------- page management ----------------------
  const [currentPage, setCurrentPage] = useState(0);
  const [TotalRecord, setTotalRecord] = useState(0);
  const itemsPerPage = 10;
  // change time to  --------------------
  dayjs.extend(customParseFormat);
  const [TimeMetting, setTimeMetting] = useState();
  const onChangeTimePicker = (time) => {
    setTimeMetting(dayjs(time).format("HH:mm:ss"));
    console.log(TimeMetting);
  };
  //----------------------------------------------------------------
  // -------get car list
  const [listcar, setListCar] = useState([]);
  const [car_name, setCar_name] = useState(null);

  const [printData, setPrintData] = useState({
    item: null,
    shouldPrint: false,
    index: null,
  });
  const printFunction = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => console.log("Preparing to print..."),
    onAfterPrint: () => console.log("Print completed."),
  });

  useEffect(() => {
    if (printData.shouldPrint && componentRef.current) {
      setPrintData({ item: null, shouldPrint: false, index: null });
      printFunction();
    }
  }, [printData, printFunction]);

  //---------------- get Joiner------------

  const [getListJoiner, setGetListJoinner] = useState([]);
  const handlePrint = async (item, index) => {
    setLoadingSpin(true);
    await new Promise((resolve) => setTimeout(resolve, 400));
    try {
      const res = await request(`letters/${item.id}`, "get", {});
      if (res) {
        setGetListJoinner(res.API_Server_listJoiner);
        setItemPrint(item);
      }
      setPrintData({ itemPrint, shouldPrint: true, index });
      console.table(res);
    } catch (error) {
      console.error("Error fetching data for print:", error);
    } finally {
      setLoadingSpin(false);
    }
  };

  const handlePreview = async (file) => {
    setVisableModelPreviewFile(false);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    console.log("link donwload " + previewImage);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const modifiedFileList = fileList.map((file) => ({
    ...file,
    name: file.name_kh, // Replace name with name_kh if available
  }));
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const handleRemove = async (file) => {
    setImageDeletePath(file.name);
    console.log("Path delete" + imageDeletePath);
    const res = await request("letters/" + file.uid, "delete", {
      imageDeletePath,
    });
    if (res) {
      setFileList((prevFileList) =>
        prevFileList.filter((item) => item.uid !== file.uid)
      );
      console.log("delete file success");
    }
  };

  const handleConfirmDelete = () => {
    // Perform the deletion logic here, if needed
    // This function will be called when the user confirms the deletion
  };
  //--------------------------- end list photo
  const getList = async (objFilter) => {
    const param = new URLSearchParams({
      page: objFilter.page,
      itemPerPage: objFilter.itemPerPage,
      txtSearch: objFilter.txtSearch || "",
      org_code: getProfileOrg.org_code,
    });
    console.log("new get list param : ");
    try {
      await new Promise((resolve) => setTimeout(resolve, 400));
      const res = await request(`letters?${param}`, "get", {});
      if (res) {
        setListFinal(res.API_Server_ListLetters || []);
        // setListJoiner(res.API_Server_ListJoiner || []);
        setTotalRecord(res.total);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const [objFilterJoiner, setObjFilterJoiner] = useState({
    search: "", // Initial search value
    org_code: Array.isArray(api_masterGroups_code)
      ? api_masterGroups_code.join(",")
      : api_masterGroups_code,
  });
  const api_list_joiner = async (objFilterJoiner) => {
    // Join `api_masterGroups_code` into a comma-separated string
    const org_code_multtiple = Array.isArray(api_masterGroups_code)
      ? api_masterGroups_code.join(",")
      : api_masterGroups_code;
    // Prepare URL parameters
    const param = new URLSearchParams({
      search: objFilterJoiner.search,
      org_code: org_code_multtiple,
    });

    console.log("Multiple org code list param:", param.toString());

    try {
      // Send the GET request with the query parameters
      const res = await request(`lettersjoiner?${param.toString()}`, "get", {});

      if (res) {
        // Set the fetched data to the state
        setListJoiner(res.api_server_list_joiner || []);
        console.log("Fetched List Joiner:", res.api_server_list_joiner);
      }
    } catch (error) {
      // Log error for debugging
      console.error("Error fetching data:", error);
    } finally {
      // Stop loading spinner or update the loading state
      setLoading(false);
    }
  };
  const getListSingnature = async () => {
    const param = new URLSearchParams({
      org_code: getProfileOrg.org_code,
    });
    const res = await request("letterssignature?" + param, "get", {});
    if (res && res.isOk === true) {
      setListSignature(res.list_singature);
    }
  };
  useEffect(() => {
    getList(objFilter);
  }, []);

  useEffect(() => {
    const profileOrgData = JSON.parse(localStorage.getItem("profileOrgData"));
    if (profileOrgData) {
      console.log("Fetched profileOrgData:", profileOrgData);
      const logoOrg = profileOrgData.find((item) => item.param === "logo");
      if (logoOrg && logoOrg.imagelink) {
        const fullImagePath = linkPath + logoOrg.imagelink;
        console.log("Fetched image path:", fullImagePath); // Debugging log
        SetOrgImagePath(fullImagePath); // Assuming SetOrgImagePath updates the state with the image path
      } else {
        console.warn("Logo not found in the profile data.");
      }
    }
  }, []); // This will run when ListOrganizationsImage is updated

  useEffect(() => {
    console.log("this path of delete file  ", imageDeletePath);
  }, [imageDeletePath]);

  const DataJoiner = listJoiner.map((staff, index) => ({
    label: staff.staffname_kh1 + " | " + staff.position_kh,
    value: staff.staff_id_number, // Using idnumber as the value
  }));
  const DataSingnature = listSignature.map((staff, index) => ({
    label: staff.fullname + " | " + staff.position_kh,
    value: staff.username, // Using idnumber as the value
  }));
  const [StaffName, setStaffName] = useState("");
  // const onChange_UserLetter_Staffjoin = (value, options) => {
  //   console.log("Selected value(s):", value); // Log the selected values directly
  //   // Join the selected ID numbers into a string while maintaining their order
  //   const staffNamesString = value.join(", ");
  //   setStaffName(staffNamesString);
  //   setUserLetters_StaffCodeJoin(value); // Store the selected values
  //   // Update the filter with the search string (staff names) and org_code
  //   // setObjFilterJoiner({
  //   //   ...objFilterJoiner,
  //   //   search: staffNamesString, // Search with staff names
  //   // });
  //   // Get the labels (names) of the selected options
  //   // Ensure options contains the selected items with label and value
  //   const search_text = options
  //     .filter((option) => value.includes(option.value)) // Filter to get selected options
  //     .map((option) => option.label) // Map to get the labels (staff names)
  //     .join(", ");
  //   // // Call the API with the updated filter
  //   api_list_joiner({
  //     ...objFilterJoiner,
  //     search: search_text, // Ensure the search term is updated
  //   });
  // };
  const onChange_UserLetter_Staffjoin = (value) => {
    setStaffName(value);
    setUserLetters_StaffCodeJoin(value);

    // const valueString = Array.isArray(value)
    //   ? value.join(",")
    //   : value.toString();
    // // Get the last value
    // const lastValue = valueString.split(",").pop() || null;
    // let idCheck = 232323;
    // if (lastValue == idCheck) {
    //   message.warning("ID =" + lastValue + " is have leave day !");
    // } else {
    //   return null;
    // }
  };

  const [SignatureID, setSignatureID] = useState("");
  const [SingnatureName, setSingnatureName] = useState("");
  const onChange_UserLetter_Signature = (value) => {
    setSingnatureName(value);
    console.log("Selected value(s):", value);
    setSignatureID(value); // Store the selected values
  };

  const handlePageChange = async (pageNumber) => {
    try {
      setLoadingSpin(true);
      await new Promise((resolve) => setTimeout(resolve, 400));
      setCurrentPage(pageNumber);
      const objTem = {
        ...objFilter,
        page: pageNumber,
      };
      setObjFilter(objTem);
      getList(objTem);
    } catch (error) {
    } finally {
      setLoadingSpin(false);
    }
  };
  //-------------------------------------------

  // ----------------- open modle ----------
  const [visableModelPreviewFile, setVisableModelPreviewFile] = useState(false);
  const onOpenModelPreviewFile = async (item) => {
    setLoadingSpin(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setUserID(item.id);
      const res = await request("letters/" + item.file_id, "get", {});
      console.log("API response:", res);

      if (res) {
        setlistdocument(res.API_Server_ListDocument || []);
        setTotalCount(res.API_Server_Total || 0);
        const fileList = res.API_Server_ListDocument.map((docItem, index) => ({
          uid: docItem.id,
          name: docItem.file_name,
          url: linkPathPhotoDocument + docItem.file_name,
          name_kh: docItem.file_name_kh,
          status: "done",
        }));

        setFileList(fileList);
      } else {
        setFileList([]);
      }
      setLoadingSpin(false);
    } catch (error) {
    } finally {
      setVisableModelPreviewFile(true);
    }
  };
  const onCLoseModelDelete = () => {
    // setlistdocument([])
    // setFileList([])
    setVisableModelPreviewFile(false);
  };

  //---------------------------
  const getListCar = async () => {
    const param = new URLSearchParams({
      org_code: getProfileOrg.org_code,
    });
    const res = await request("lettersdriverlist?" + param, "get", {});
    console.log("API response:", res);
    if (res) {
      setListCar(res.list_driver);
    }
  };
  //------------- save ----------
  const [visableNewModel, setVisableNewModel] = useState(false);
  const onOpenSaveNew = () => {
    api_list_joiner(objFilterJoiner);
    const today = new Date();
    // Formatting the date if needed (e.g., "YYYY-MM-DD")
    const formattedDate = today.toISOString().split("T")[0];
    setFileList(null);
    setSignatureID("");
    setUserID("");
    setTotalCount("");
    setStaffName("");
    setFileList([]);
    setlistdocument([]);
    setPreviewImage("");
    setPreviewTitle("");
    setUserLetters_Type("ចូល");
    setUserLetters_FromTo("");
    setUserLettter_number("");
    setUserLettter_Date(dayjs());
    setUserLeters_Note("");
    setUserLetters_StaffCodeJoin(null);
    setUserLettter_Description("");
    setUserLetters_DateStart(dayjs());
    setUserLetters_DateEnd(dayjs());
    setUserLetters_location("");
    setUserLetters_PrepareBy("");
    setUserLetters_SupportBy("");
    setCar_name("");
    setVisableNewModel(true);
    setEnable(true);
    setDisable(false);
  };

  //-------------   enable and disable ---------------

  const [enable, setEnable] = useState(false);
  const [disable, setDisable] = useState(false);
  const handleCheckboxChange_Enable = (e) => {
    setEnable(e.target.checked);
    setDisable(false);
  };
  const handleCheckboxChange_Diable = (e) => {
    setDisable(e.target.checked);
    setEnable(false);
  };
  //--------------------------------------------

  //------------------- save data to letters --------
  const [listDuplicate, setListDuplicate] = useState([]);
  const onSaveData = async () => {
    try {
      const formData = new FormData();
      formData.append("id", UserID);
      formData.append("transfer_name", UserLetters_type);
      formData.append("letters_from", UserLetters_FromTo);
      formData.append("letters_number", UserLettter_number);
      formData.append(
        "letters_date",
        dayjs(UserLettter_Date).format("YYYY-MM-DD")
      );
      formData.append("letters_type", UserLetters_Note);
      formData.append("invite_staff", StaffName);
      formData.append("join_des", UserLettter_Description);
      formData.append(
        "joindate_start",
        dayjs(UserLetters_DateStart).format("YYYY-MM-DD")
      );
      formData.append(
        "joindate_end",
        dayjs(UserLetters_DateEnd).format("YYYY-MM-DD")
      );
      formData.append("location", UserLetters_location);
      formData.append("prepare_by", UserLetters_PrepareBy);
      formData.append("support_by", UserLetters_SupportBy);
      formData.append("create_By", "admin update");
      formData.append("status", enable ? 1 : disable ? 0 : 0);
      formData.append("file_id", update_file_id);
      formData.append("other", "l001");
      formData.append("carusing", car_name);
      formData.append("org_code", getProfileOrg.org_code);
      formData.append("signature_id", SignatureID);
      formData.append("p_time", TimeMetting);
      formData.append("p_clothing", UserLetters_Clothing);
      formData.append(
        "p_date_document",
        dayjs(UserLetters_DateDocument).format("YYYY-MM-DD")
      );
      formData.append(
        "p_date_signature",
        dayjs(UserLetters_DateSignature).format("YYYY-MM-DD")
      );

      fileList.forEach((file) => {
        if (file.originFileObj instanceof Blob) {
          formData.append(
            UserID !== "" ? "upload_file_update" : "upload_file",
            file.originFileObj,
            file.name
          );
        } else {
          console.error("File object is not a Blob:", file);
        }
      });

      // Append each file to FormData
      setVisableNewModel(false);
      setLoadingSpin(true);
      const p_method = UserID === "" ? "POST" : "PUT";

      await new Promise((resolve) => setTimeout(resolve, 500));
      console.log("Save or Update IDstaff: ", StaffName);

      const res = await request("letters", p_method, formData);
      if (res && res.isOk == true) {
        message.success(
          UserID == "" ? "Report Save Success!" : "Report Update Success!",
          5
        );
      } else if (res && res.isOk == false) {
        setListDuplicate(res.api_check_result);
        message.warning(
          <div>
            {"Can't Save! Please try again"}
            <br />
            <div>
              {res.api_check_result.length > 0 && (
                <ul style={{ fontWeight: "bold", color: "#ec7063" }}>
                  {res.api_check_result.map((item, index) => (
                    <li key={index}>
                      {item.fullname} {" មាន ៖ "} {item.num_leave_eng}{" "}
                      {" ចាប់ពីថ្ងៃទី ៖"}{" "}
                      {dayjs(item.start_date).format("DD/MM/YYYY")}
                      {" ដល់ថ្ងៃទី ៖"}
                      {dayjs(item.end_date).format("DD/MM/YYYY")}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>,
          15
        ); // Show the message warning
      }
    } catch (error) {
    } finally {
      getList(objFilter);
      setLoadingSpin(false);
    }
  };

  const [DownloadReport, setDownloadReport] = useState(false);
  const onClickDownloadReport = () => {
    setDownloadReport(true);
  };

  //-------------------------
  const onCancelModel = () => {
    setFileList(null);
    setlistdocument(null);
    setFileList([]);
    setlistdocument([]);

    setVisableNewModel(false);
  };
  const onCanceDelete = () => {
    setVisableModelPreviewFile(false);
  };

  const onChange_UserLetters_Type = (event) => {
    setUserLetters_Type(event);
    console.log(UserLetters_type);
  };
  const onChange_UserLetters_FromTo = (event) => {
    setUserLetters_FromTo(event.target.value);
  };
  const onChange_UserLettter_number = (event) => {
    setUserLettter_number(event.target.value);
  };
  const onChange_UserLetters_Date = (date) => {
    setUserLettter_Date(dayjs(date).format("YYYY-MM-DD"));
    console.log("onChange_UserLetters_Date", UserLettter_Date);
  };
  const onChange_UserLetters_Note = (value) => {
    setUserLeters_Note(value);
  };
  const onChange_UserLettter_Description = (event) => {
    setUserLettter_Description(event.target.value);
  };
  const onChange_UserLetters_DateStart = (date) => {
    setUserLetters_DateStart(dayjs(date).format("YYYY-MM-DD"));
  };
  const onChange_UserLetters_DateEnd = (date) => {
    setUserLetters_DateEnd(dayjs(date).format("YYYY-MM-DD"));
  };

  const onChange_UserLetters_DateDocument = (date) => {
    setUserLetters_DateDocument(dayjs(date).format("YYYY-MM-DD"));
  };
  const onChange_UserLetters_DateSignature = (date) => {
    setUserLetters_DateSignature(dayjs(date).format("YYYY-MM-DD"));
  };

  const onChange_UserLetters_location = (event) => {
    setUserLetters_location(event.target.value);
  };
  const onChange_UserLetters_PrepareBy = (event) => {
    setUserLetters_PrepareBy(event.target.value);
  };
  const onChange_UserLetters_SupportBy = (event) => {
    setUserLetters_SupportBy(event.target.value);
  };
  const onChange_UserLetters_Clothing = (event) => {
    setUserLetters_Clothing(event.target.value);
  };
  useEffect(() => {
    console.log("user file id " + UserFile_ID);
  }, [UserFile_ID]);
  useEffect(() => {
    console.log("File list updated:", fileList);
  }, [fileList, listdocument]);
  const [update_file_id, setUpdateFileID] = useState("");
  const [SaveFile, setSaveFile] = useState();

  const onEditData = async (item) => {
    getListSingnature();
    api_list_joiner(objFilterJoiner);
    setLoadingSpin(true);
    try {
      setUserFile_ID(item.file_id);
      console.log("user file id " + UserFile_ID);

      const res = await request("letters/" + item.file_id, "get", {});
      console.log("API response:", res);

      if (res) {
        setlistdocument(res.API_Server_ListDocument || []);
        console.log("List document:", res.API_Server_ListDocument);

        setTotalCount(res.API_Server_Total || 0);
        console.log("Total count:", res.API_Server_Total);
        //  setListCar(res.API_Server_CarUsing);

        const fileList = res.API_Server_ListDocument.map((docItem, index) => ({
          uid: docItem.id,
          name: docItem.file_name,
          name_kh: docItem.file_name_kh,
          url: linkPathPhotoDocument + docItem.file_name,
          status: "done",
        }));
        console.log("Upload", fileList);
        setFileList(fileList);
      } else {
        console.log("No documents found.");
        setFileList([]);
      }

      setStaffName(item.invite_staff);
      setSignatureID(item.signature_id);
      setUserID(item.id);
      const arrayValueForSelect = item.invite_staff
        .split(",")
        .map((value) => value.trim());
      const SingnatureNameSelect = item.signature_id
        .split(",")
        .map((value) => value.trim());

      setUserLetters_Type(item.transfer_name);
      setUserLetters_FromTo(item.letters_from);
      setUserLettter_number(item.letters_number);
      setUserLettter_Date(item.letters_date);
      setUserLeters_Note(item.letters_type);
      setUserLetters_StaffCodeJoin(arrayValueForSelect);
      setUserLettter_Description(item.join_des);
      setUserLetters_DateStart(item.joindate_start);
      setUserLetters_DateEnd(item.joindate_end);
      setUserLetters_location(item.location);
      setUserLetters_PrepareBy(item.prepare_by);
      setUserLetters_SupportBy(item.support_by);
      setUpdateFileID(item.file_id);
      setCar_name(item.carusing);
      setSingnatureName(SingnatureNameSelect);
      setTimeMetting(item.time_meeting);

      setUserLetters_Clothing(item.clothing_meeting);

      setUserLetters_DateDocument(item.date_document);
      setUserLetters_DateSignature(item.date_signature);

      setVisableNewModel(true);

      if (item.status === 1) {
        setEnable(true);
        setDisable(false);
      } else {
        setEnable(false);
        setDisable(true);
      }
    } catch (error) {
      // Handle errors appropriately
      console.error("Error occurred:", error);
    } finally {
      setLoadingSpin(false);
    }
  };
  const onClick_Download = () => {
    window.open(previewImage, "_blank");
  };
  const formattedDate = (date) => {
    const [day, month, year] = date.split("/");
    return ` ${"ថ្ងៃទី" + toKhmerDay(day)} ${"ខែ" + toKhmerMonth(month)} ${
      "ឆ្នាំ" + toKhmerYear(year)
    }`;
  };

  // print លិខិតឧទ្ទេសនាម
  class MyComponentToPrint extends React.Component {
    render() {
      return (
        <div className="print-content">
          {itemPrint && (
            <ComponentToPrint
              reason={itemPrint.join_des}
              node={
                "លិខិតលេខ​: " +
                itemPrint.letters_number +
                "  ចុះ" +
                formattedDate(formateDateClient(itemPrint.letters_date)) +
                " របស់ " +
                itemPrint.prepare_by
              }
              liststaff={getListJoiner}
              date_start={formattedDate(
                formateDateClient(itemPrint.joindate_start)
              )}
              date_end={formattedDate(
                formateDateClient(itemPrint.joindate_end)
              )}
              support_by={itemPrint.support_by}
              car_using={itemPrint.carusing}
              Org_ImagePath={Org_ImagePath}
              name_signature={itemPrint.name_signature}
              postion_signature={itemPrint.postion_signature}
              org_cover_by={org_cover_by}
              date_document={itemPrint.date_document}
              date_signature={itemPrint.date_signature}
              do_in_provinceName={Do_In_ProvinceName}
              org_type_string={stringType}
              org_type_code={org_type_code}
              location_contact={location_contact}
              location={itemPrint.location}
            />
          )}
        </div>
      );
    }
  }
  // print លិខិតអញ្ញើញ
  class MyComponentToPrint_Index1 extends React.Component {
    render() {
      return (
        <div className="print-content">
          {itemPrint && (
            <ComponentToPrint_Index1
              reason={itemPrint.join_des}
              node={
                "លិខិតលេខ​ " +
                itemPrint.letters_number +
                "  ចុះ" +
                formattedDate(formateDateClient(itemPrint.letters_date)) +
                " របស់ " +
                itemPrint.prepare_by
              }
              liststaff={getListJoiner}
              date_start={formattedDate(
                formateDateClient(itemPrint.joindate_start)
              )}
              date_end={formattedDate(
                formateDateClient(itemPrint.joindate_end)
              )}
              support_by={itemPrint.support_by}
              car_using={itemPrint.carusing}
              Org_ImagePath={Org_ImagePath}
              name_signature={itemPrint.name_signature}
              postion_signature={itemPrint.postion_signature}
              org_cover_by={org_cover_by}
              location={itemPrint.location}
              time_meeting={itemPrint.time_meeting}
              clothing_meeting={itemPrint.clothing_meeting}
              date_document={itemPrint.date_document}
              date_signature={itemPrint.date_signature}
              do_in_provinceName={Do_In_ProvinceName}
              org_type_code={org_type_code}
              location_contact={location_contact}
            />
          )}
        </div>
      );
    }
  }
  // print លិខិតបេសកកម្មមាន ក្រៅខេត្ត នឹងយោង
  class MyComponentToPrint_Index2 extends React.Component {
    render() {
      return (
        <div className="print-content">
          {itemPrint && (
            <ComponentToPrint_Index2
              reason={itemPrint.join_des}
              node={
                "លិខិតលេខ​ " +
                itemPrint.letters_number +
                "  ចុះ" +
                formattedDate(formateDateClient(itemPrint.letters_date)) +
                " របស់ " +
                itemPrint.prepare_by
              }
              liststaff={getListJoiner}
              date_start={formattedDate(
                formateDateClient(itemPrint.joindate_start)
              )}
              date_end={formattedDate(
                formateDateClient(itemPrint.joindate_end)
              )}
              support_by={itemPrint.support_by}
              car_using={itemPrint.carusing}
              Org_ImagePath={Org_ImagePath}
              name_signature={itemPrint.name_signature}
              postion_signature={itemPrint.postion_signature}
              org_cover_by={org_cover_by}
              location={itemPrint.location}
              time_meeting={itemPrint.time_meeting}
              clothing_meeting={itemPrint.clothing_meeting}
              date_document={itemPrint.date_document}
              date_signature={itemPrint.date_signature}
              do_in_provinceName={Do_In_ProvinceName}
              org_type_code={org_type_code}
              location_contact={location_contact}
            />
          )}
        </div>
      );
    }
  }
  // print លិខិតបេសកកម្មមាន ក្រៅខេត្ត នឹងយោង
  class MyComponentToPrint_Index2_in extends React.Component {
    render() {
      return (
        <div className="print-content">
          {itemPrint && (
            <ComponentToPrint_Index2_in
              reason={itemPrint.join_des}
              node={
                "លិខិតលេខ​ " +
                itemPrint.letters_number +
                "  ចុះ" +
                formattedDate(formateDateClient(itemPrint.letters_date)) +
                " របស់ " +
                itemPrint.prepare_by
              }
              liststaff={getListJoiner}
              date_start={formattedDate(
                formateDateClient(itemPrint.joindate_start)
              )}
              date_end={formattedDate(
                formateDateClient(itemPrint.joindate_end)
              )}
              support_by={itemPrint.support_by}
              car_using={itemPrint.carusing}
              Org_ImagePath={Org_ImagePath}
              name_signature={itemPrint.name_signature}
              postion_signature={itemPrint.postion_signature}
              org_cover_by={org_cover_by}
              location={itemPrint.location}
              time_meeting={itemPrint.time_meeting}
              clothing_meeting={itemPrint.clothing_meeting}
              date_document={itemPrint.date_document}
              date_signature={itemPrint.date_signature}
              do_in_provinceName={Do_In_ProvinceName}
              org_type_code={org_type_code}
              location_contact={location_contact}
            />
          )}
        </div>
      );
    }
  }

  const onChangeCarusing = (value) => {
    setCar_name(value);
  };
  const getFileType = (file) => {
    // Get file extension from the name
    const extension = file.name.split(".").pop().toLowerCase();
    // Check if the file extension is 'pdf'
    return extension === "pdf" ? "application/pdf" : "";
  };
  // const itemRender = (originNode, file, fileList, actions) => {
  //   const isPDF = getFileType(file) === "application/pdf";
  //   const  isExcel = getFileType(file) === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  //   const isWord = getFileType(file) === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  //   const isPowerPoint = getFileType(file) === "application/vnd.openxmlformats-officedocument.presentationml.presentation";

  //   return (
  //     <div style={{ height: 100, width: 100 }}>
  //       {isPDF ? (
  //         <img
  //           src="https://seeklogo.com/images/A/acrobat-file-pdf-logo-37A1BFDE35-seeklogo.com.png"
  //           alt="PDF Preview"
  //           style={{ alignItems: "center", width: "90%", height: "100%" }}
  //         />
  //       ) : (
  //         originNode
  //       )}

  //       <div style={{ textAlign: "center", marginTop: 8 }}>{file.name_kh}</div>
  //     </div>
  //   );
  // };
  const itemRender = (originNode, file, fileList, actions) => {
    const getFileType = (file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      switch (extension) {
        case "pdf":
          return "application/pdf";
        case "xlsx":
        case "xls":
          return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        case "docx":
        case "doc":
          return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        case "pptx":
        case "ppt":
          return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        default:
          return "";
      }
    };

    const isPDF = getFileType(file) === "application/pdf";
    const isExcel =
      getFileType(file) ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const isWord =
      getFileType(file) ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    const isPowerPoint =
      getFileType(file) ===
      "application/vnd.openxmlformats-officedocument.presentationml.presentation";

    return (
      <div style={{ height: 100, width: 100 }}>
        {isPDF ? (
          <img
            src="https://seeklogo.com/images/A/acrobat-file-pdf-logo-37A1BFDE35-seeklogo.com.png"
            alt="PDF Preview"
            style={{ alignItems: "center", width: "90%", height: "100%" }}
          />
        ) : isExcel ? (
          <img
            src="https://i.pinimg.com/originals/1b/db/8a/1bdb8ac897512116cbac58ffe7560d82.png"
            alt="Excel Preview"
            style={{ alignItems: "center", width: "100%", height: "100%" }}
          />
        ) : isWord ? (
          <img
            src="https://static.vecteezy.com/system/resources/previews/027/179/388/non_2x/microsoft-word-icon-logo-symbol-free-png.png"
            alt="Word Preview"
            style={{ alignItems: "center", width: "100%", height: "100%" }}
          />
        ) : isPowerPoint ? (
          <img
            src="https://seeklogo.com/images/M/microsoft-powerpoint-logo-5EAA3DDBA5-seeklogo.com.png"
            alt="PowerPoint Preview"
            style={{ alignItems: "center", width: "100%", height: "100%" }}
          />
        ) : (
          originNode
        )}

        <div style={{ textAlign: "center", marginTop: 8 }}>{file.name_kh}</div>
      </div>
    );
  };

  const deleteList = async (item) => {
    setLoadingSpin(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      console.log("onDeleteListItem", item);
      const res = await request("letterslist/" + item, "delete", {});
      if (res) {
        openNotification("success", "Success", "Successfully deleted list");
        getList(objFilter);
      } else {
        openNotification("error", "Error", "Failed to delete list");
      }
    } catch (error) {
      openNotification("error", "Error", error.message);
    } finally {
      setLoadingSpin(false);
    }
  };
  const onDeleteListItem = async (item) => {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: "តើ អ្នកចង់លុបលិខិតនេះចេញពីប្រព័ន្ធដែរីទេ?",
        okText: "បាទ / Yes",
        cancelText: "ទេ / No",
        onOk: () => {
          deleteList(item.id);
          getList(objFilter);
        },
        // onCancel: () => {reject}
      });
    });
  };
  const onChangeSearch = (e) => {
    const KeyWork_Search = e.target.value;
    setCurrentPage(1);
    const objTemSearch = {
      ...objFilter,
      txtSearch: KeyWork_Search,
      page: 1,
    };
    console.log(objTemSearch);
    setObjFilter(objTemSearch);
    getList(objTemSearch);
  };
  useEffect(() => {}, [currentPage]);

  const [openPop, setOpenPop] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = () => {
    setDriverValue("");
    setOpenPop(true);
  };
  const [stringType, setStringType] = useState(null); // State for

  useEffect(() => {
    let stringType = null; // Default state
    console.log("code of org : ", getProfileOrg.org_type);
    switch (getProfileOrg.org_type) {
      case 1:
        stringType = null; // For organization PHD
        break;
      case 2:
      case 3:
        stringType = `លោកប្រធានមន្ទីរសុខាភិបាលរដ្ឋបាលខេត្ត ${Do_In_ProvinceName}`;
        break;
      case 4:
      case 5:
      case 6:
        stringType = `លោកប្រធានការិយាល័យសុខាភិបាលស្រុកប្រតិបត្តិ ${Do_In_ProvinceName}`;
        break;
      default:
        stringType = ""; // Handle unexpected org_type_code
        break;
    }

    setStringType(stringType);
    console.log(stringType);
  }, [Do_In_ProvinceName]);

  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(async () => {
      try {
        const data = {
          driver_value: Driver_Value,
          org_code: getProfileOrg.org_code,
        };
        const res = await request("lettersdriver", "post", data);
        if (res && res.isOk === true) {
          Alert("Save Successfully");
          console.log("Save Success", res);
        } else {
          Alert(res.message);
        }
      } catch (error) {
      } finally {
        setOpenPop(false);
        setConfirmLoading(false);
        setDriverValue("");
      }
    }, 2000);
  };

  const handleCancel_pro = () => {
    setOpenPop(false);
  };
  const [Driver_Value, setDriverValue] = useState("");
  const onChangeInputDriver = (event) => {
    setDriverValue(event.target.value);
  };
  const onClickListDriver = () => {
    getListCar();
  };
  const items = [
    {
      label: "លិខិតឧទ្ទេសនាម",
      key: "0",
      // icon: <UserOutlined />,
    },
    {
      label: "លិខិតអញ្ញើញ",
      key: "1",
    },
    {
      label: "លិខិតបេសកម្ម ក្រៅ",
      key: "2",
      // icon: <UserOutlined />,
    },
    {
      label: "លិខិតបេសកម្ម ក្នុង",
      key: "3",
      // icon: <UserOutlined />,
    },
  ];
  const menu = (id) => (
    <Menu
      items={items.map((item, index) => ({
        ...item,
        ...index,
        onClick: () => handlePrint(id, index),
      }))}
    />
  );
  const PrintWrapper = React.forwardRef(({ data }, ref) => {
    if (!data) return null; // Avoid rendering null
    return (
      <div ref={ref}>
        {data.index === 0 && <MyComponentToPrint item={data.item} />}
        {data.index === 1 && <MyComponentToPrint_Index1 item={data.item} />}
        {data.index === 2 && <MyComponentToPrint_Index2 item={data.item} />}
        {data.index === 3 && <MyComponentToPrint_Index2_in item={data.item} />}
      </div>
    );
  });

  const StyleText_Label = {
    margin: 5,
    color: "darkgray",
    paddingTop: 10,
    fontFamily: "Content, sans-serif",
  };

  const handleSearch = debounce((inputValue) => {
    // Only update the search state and call the API after debounce
    setSearchText(inputValue);
    api_list_joiner({ ...objFilterJoiner, search: inputValue });
  }, 300); // Debounce for 300ms
  const onClickListSignature = () => {
    getListSingnature();
  };
  return (
    <div>
      <div className="sprinSave-container">
        {/* Your other components */}
        {loadingSpin && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#2e86c1"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>

      <div style={boxHeader}>
        <div style={{ display: "flex", justifyItems: "center", width: "auto" }}>
          <Space>
            {boxHeaderTagText("ការគ្រប់គ្រង់លិខិត " + TotalRecord)}
            <Input
              style={{ width: 200 }}
              onChange={onChangeSearch}
              className="styleTageStatusForm"
              placeholder="Search..."
              allowClear
            />
          </Space>
        </div>
        <div>
          <Space>
            <Button
              className="buttonstylekhmer"
              onClick={onClickDownloadReport}
              type="primary"
              icon={<BsDownload />}
            >
              {" "}
              ទាយយករបាយការណ៍
            </Button>
            <Button
              onClick={onOpenSaveNew}
              disabled={!addvalue}
              className="custom-form-item"
              type="primary"
              icon={<PlusCircleOutlined />}
            >
              បន្ថែមថ្មី
            </Button>
          </Space>{" "}
        </div>
      </div>

      <div>
        {loading ? (
          <div className="sprinCustome">
            <Circles
              height="80"
              width="80"
              color="#2e86c1"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Table
            // striped
            // bordered
            // hover
            className="rounded-table"
            itemsPerPage={10}
          >
            <thead>
              <tr class="table-success">
                <th>NO</th>
                <th>ប្រភេទលិខិត </th>
                <th>លិខិតលេខ</th>
                <th>ខ្លឹមសារលិខិត</th>
                <th>ថ្ងៃខែឆ្នាំបេសកកម្ម</th>
                <th>ទីតាំងបេសកកម្ម</th>
                <th>រៀបចំដោយ និងថវិការ</th>
                <th>ស្ថានភាព</th>
                <th>សកម្មភាព</th>
              </tr>
            </thead>
            <tbody>
              {ListFinal.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>

                  <td>
                    <div>
                      <div>{getLettersContent(item.transfer_name)}</div>
                      <div style={{ paddingTop: 5 }}>
                        <Tag bordered={false} color="processing">
                          {dayjs(item.letters_date).format("DD/MM/YYYY")}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div style={{ padding: 2 }}>
                        <Tag style={{ fontFamily: "Content" }} color="#2db7f5">
                          {item.letters_from}
                        </Tag>
                      </div>
                      <div style={{ padding: 2 }}>
                        <Tag
                          style={{ fontFamily: "Content" }}
                          bordered={false}
                          color="processing"
                        >
                          {item.letters_number}
                        </Tag>
                      </div>
                      <div style={{ padding: 2 }}>
                        <Tag style={{ fontFamily: "Content" }} color="#2db7f5">
                          {item.letters_type}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  {/* {getTageStyle( item.join_des)} */}

                  <td class="word-wrap">
                    <div style={{ whiteSpace: "pre-wrap", color: "#108ee9" }}>
                      <BiCheckDouble /> {item.join_des}
                    </div>
                  </td>
                  <td>
                    <div>
                      <div>
                        <Tag bordered={false} color="processing">
                          {dayjs(item.joindate_start).format("DD/MM/YYYY")}
                        </Tag>
                      </div>
                      <div>
                        <Tag bordered={false} color="processing">
                          {dayjs(item.joindate_end).format("DD/MM/YYYY")}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div style={{ color: "rgb(11, 145, 112)" }}>
                        <GrLocation /> {item.location}
                      </div>
                      <div>
                        <Tag
                          style={{
                            paddingTop: 2,
                            fontFamily: "Content",
                            width: 120,
                            whiteSpace: "normal",
                          }}
                        >
                          {item.staff_name_substring}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div>{item.prepare_by}</div>
                      <div>
                        <Tag style={{ fontFamily: "Content" }} color="#f50">
                          {item.support_by}
                        </Tag>
                      </div>
                    </div>
                  </td>
                  <td>{getStatusContent(item.status)}</td>
                  <td style={{ width: 100 }}>
                    <Stack gap={1} direction="horizontal">
                      <Button
                        onClick={() => onOpenModelPreviewFile(item)}
                        type="primary"
                        icon={<FaRegEye />}
                      />
                      <Button
                        disabled={!Editvalue}
                        onClick={() => onEditData(item)}
                        type="primary"
                        icon={<MdOutlineEdit />}
                      />
                      <div className="print-container">
                        {/* <MyComponentToPrint
                          ref={componentRef}
                          key={index}
                          item={itemPrint}
                        /> */}
                        <PrintWrapper data={printData} ref={componentRef} />
                        <Dropdown
                          overlayStyle={{ fontFamily: "Content" }}
                          overlay={menu(item)}
                          trigger={["click"]}
                        >
                          <Button
                            className="buttonstylekhmer"
                            type="primary"
                            disabled={!printValue}
                            icon={<DownloadOutlined />}
                          />
                        </Dropdown>
                      </div>
                      <Button
                        disabled={!printValue}
                        type="primary"
                        onClick={() => onDeleteListItem(item)}
                        icon={<MdOutlineDelete />}
                        danger
                      />

                      {/* <Button disabled={!Editvalue} type="primary" onClick={()=>onOpenEdit(item)}  >Edit</Button>{' '}
                                    <Button  disabled={!deletevalue}  type="primary" onClick={()=>onDeleteModel(item)} danger>Delete</Button> */}
                    </Stack>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <Pagination
          onChange={handlePageChange}
          className="custom-pagination"
          size="large"
          current={currentPage}
          pageSize={itemsPerPage}
          total={TotalRecord}
        />
        <Modal
          title={UserID == "" ? "បង្កើតថ្មី" : "កែប្រែ"}
          className="styleTageStatusForm"
          open={visableNewModel}
          width={1280}
          footer={[
            <div>
              <Space>
                {" "}
                <Button onClick={onCancelModel}>Cancel</Button>
                <Button type="primary" onClick={onSaveData}>
                  {UserID == "" ? "Save" : "Update"}
                </Button>
              </Space>
            </div>,
          ]}
        >
          <div className="Model-Setting-Grid">
            <div style={{ padding: "10px" }}>
              <div>
                <p style={StyleText_Label}>ប្រភេទលិខិត ៖</p>
                <Select
                  className="styleTageStatusForm"
                  value={UserLetters_type}
                  onChange={onChange_UserLetters_Type}
                  style={{ width: "100%" }}
                  allowClear
                >
                  <Option className="styleTageStatusForm" value="ចូល">
                    ចូល
                  </Option>
                  <Option className="styleTageStatusForm" value="ចេញ">
                    ចេញ
                  </Option>
                  <Option className="styleTageStatusForm" value="ឯកសារផ្សេងៗ">
                    ឯកសារផ្សេងៗ
                  </Option>
                </Select>
                <p style={StyleText_Label}>
                  {UserLetters_type === "ចូល" ? "លិខិតចូលមកពី" : ""}
                  {UserLetters_type === "ឯកសារផ្សេងៗ"
                    ? "ឯកសារផ្សេងនៅក្នុង"
                    : ""}
                  {UserLetters_type === "ចេញ" ? "ចេញទៅ" : ""} ៖
                </p>
                <Input
                  value={UserLetters_FromTo}
                  onChange={onChange_UserLetters_FromTo}
                  className="styleTageStatusForm"
                  placeholder="Input...."
                />

                <p style={StyleText_Label}>លិខិតលេខ ៖</p>
                <Input
                  value={UserLettter_number}
                  onChange={onChange_UserLettter_number}
                  className="styleTageStatusForm"
                  placeholder="Input...."
                />

                <p style={StyleText_Label}>
                  {UserLetters_type === "ចូល" ? "ថ្ងៃខែ លិខិតចូល" : ""}
                  {UserLetters_type === "ឯកសារផ្សេងៗ"
                    ? "ថ្ងៃរក្សារ ឯកសារផ្សេង"
                    : ""}
                  {UserLetters_type === "ចេញ" ? "ថ្ងៃខែ លិខិតចេញ" : ""} ៖
                </p>
                <DatePicker
                  format={"DD/MM/YYYY"}
                  value={dayjs(UserLettter_Date)}
                  onChange={onChange_UserLetters_Date}
                  readOnly={false}
                  style={{ width: "100%", height: 40 }}
                />

                <p style={StyleText_Label}>កំណត់សម្គាល់ លិខិត ៖</p>

                <Select
                  className="styleTageStatusForm"
                  value={UserLetters_Note}
                  onChange={onChange_UserLetters_Note}
                  style={{ width: "100%" }}
                  allowClear
                >
                  <Option className="styleTageStatusForm" value="លិខិតអញ្ជើញ">
                    លិខិតអញ្ជើញ
                  </Option>
                  <Option
                    className="styleTageStatusForm"
                    value="លិខិតឧទ្ទេសនាមបេសកកម្ម"
                  >
                    លិខិតឧទ្ទេសនាមបេសកកម្ម
                  </Option>
                  <Option
                    className="styleTageStatusForm"
                    value="លិខិតបង្គាប់ការ"
                  >
                    លិខិតបង្គាប់ការ
                  </Option>
                  <Option className="styleTageStatusForm" value="លិខិតផ្សេងៗ">
                    លិខិតផ្សេងៗ
                  </Option>
                </Select>
              </div>
            </div>
            <div style={{ padding: "10px" }}>
              <div>
                <p style={StyleText_Label}>ខ្លឹមសារលិខិត ៖</p>
                <Input.TextArea
                  value={UserLettter_Description}
                  onChange={onChange_UserLettter_Description}
                  className="styleTageStatusForm"
                  rows={8}
                  placeholder="បញ្ចូល ខ្លឹមសាររបស់ឯកសារ....."
                />
                <p style={StyleText_Label}>ចាប់ផ្តើមថ្ងៃទី ៖</p>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    value={dayjs(UserLetters_DateStart)}
                    onChange={onChange_UserLetters_DateStart}
                    style={{ width: "100%", height: 40 }}
                  />

                  {UserLetters_Note == "លិខិតអញ្ជើញ" && (
                    <TimePicker
                      value={
                        TimeMetting ? dayjs(TimeMetting, "HH:mm:ss") : null
                      } // Convert DB time to dayjs object
                      style={{ marginLeft: 10, width: "80%", height: 40 }}
                      onChange={onChangeTimePicker}
                      format={"HH:mm A"}
                      use12Hours={true}
                      defaultOpenValue={dayjs("00:00", "HH:mm")}
                    />
                  )}
                </div>
                <p style={StyleText_Label}>ដល់ថ្ងៃទី ៖</p>
                <DatePicker
                  format={"DD/MM/YYYY"}
                  value={dayjs(UserLetters_DateEnd)}
                  onChange={onChange_UserLetters_DateEnd}
                  style={{ width: "100%", height: 40 }}
                />
                <p style={StyleText_Label}>ទីតាំងបេសកកម្ម | ប្រជុំ ៖</p>
                <Input.TextArea
                  value={UserLetters_location}
                  onChange={onChange_UserLetters_location}
                  className="styleTageStatusForm"
                  rows={2}
                  placeholder="បញ្ចូល ទីតាំងបេសកកម្ម​ ឬទីកន្លែងប្រជុំ....."
                />
              </div>
            </div>
            <div style={{ padding: "10px" }}>
              <div>
                <p style={StyleText_Label}>រៀបចំដោយ ៖</p>
                <Input.TextArea
                  value={UserLetters_PrepareBy}
                  onChange={onChange_UserLetters_PrepareBy}
                  className="styleTageStatusForm"
                  rows={2}
                  placeholder="បញ្ចូល រៀបចំដោយ....."
                />
                <p style={StyleText_Label}>ឧបត្ថមលុយដោយ ៖</p>
                <Input.TextArea
                  value={UserLetters_SupportBy}
                  onChange={onChange_UserLetters_SupportBy}
                  className="styleTageStatusForm"
                  rows={2}
                  placeholder="បញ្ចូល រៀបចំដោយ....."
                />
                <p style={StyleText_Label}>ឈ្មោះមន្ត្រីចូលរួម | ប្រជុំ៖</p>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%", height: "auto" }}
                  placeholder="ជ្រើសឈ្មោះមន្ត្រី"
                  value={UserLetters_StaffCodeJoin}
                  onChange={onChange_UserLetter_Staffjoin}
                  options={DataJoiner}
                  onSearch={handleSearch}
                  showSearch // Enable search functionality
                  filterOption={(inputValue, option) => {
                    // Use the search state to filter options
                    return (
                      option.label
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) >= 0
                    );
                  }}
                  // filterOption={(inputValue, option) => {
                  //   console.log("inputValue:", inputValue);
                  //   api_list_joiner({ ...objFilterJoiner, search: inputValue });
                  //   console.log("option.label:", option.label);
                  //   return (
                  //     option.label
                  //       .toLowerCase()
                  //       .indexOf(inputValue.toLowerCase()) >= 0
                  //   );
                  // }}

                  // here is what the "toLower" function looks like just to convert string to lower case
                />
                <p style={StyleText_Label}>មធ្យោបាយធ្វើរដំណើរ​ ៖</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <Select
                    showSearch={true}
                    onClick={onClickListDriver}
                    style={{ width: 250, height: 40 }}
                    // showSearch
                    placeholder="Select a Car"
                    // optionFilterProp="children"
                    onChange={onChangeCarusing}
                    value={car_name}
                    allowClear
                  >
                    {listcar.map((option) => (
                      <Option
                        className="styleTageStatusForm"
                        key={option.id}
                        value={option.car_name}
                      >
                        {option.car_name}{" "}
                        {/* Replace with the field from your MySQL table */}
                      </Option>
                    ))}
                  </Select>

                  <Popconfirm
                    icon={<PlusCircleOutlined />}
                    title="Create New"
                    description={
                      <div style={{ margin: 20 }}>
                        <Divider orientation="left">
                          បញ្ជូលមធ្យោបាយធ្វើដំណើរថ្មី ៖
                        </Divider>
                        <Input
                          onChange={onChangeInputDriver}
                          style={{ width: "300px", height: 40 }}
                          placeholder="input Value ..."
                        />
                      </div>
                    }
                    open={openPop}
                    onConfirm={handleOk}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={handleCancel_pro}
                  >
                    <Button
                      type="primary"
                      onClick={showPopconfirm}
                      icon={<PlusCircleOutlined />}
                    />
                  </Popconfirm>
                </div>

                <p style={StyleText_Label}>ស្ថានភាព / Status</p>

                <Tag>
                  <div style={{ padding: 10 }}>
                    <Checkbox
                      checked={enable}
                      onChange={handleCheckboxChange_Enable}
                    >
                      Enable
                    </Checkbox>
                    <Checkbox
                      style={{ color: "red" }}
                      checked={disable}
                      onChange={handleCheckboxChange_Diable}
                    >
                      Disable
                    </Checkbox>
                  </div>
                </Tag>
              </div>
            </div>
            <div style={{ padding: "10px" }}>
              <div>
                <p style={StyleText_Label}>ចុះហត្ថលេខាលើលិខិតដោយ ៖</p>
                <Select
                  allowClear
                  style={{ width: "100%", height: 40 }}
                  placeholder="Please select"
                  value={SingnatureName}
                  onClick={onClickListSignature}
                  onChange={onChange_UserLetter_Signature}
                  options={DataSingnature}
                  showSearch // Enable search functionality
                  filterOption={(inputValue, option) =>
                    option.label
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  } // Custom
                />
                <p style={StyleText_Label}>សម្លៀកបំពាក់ ចូលរួមប្រជុំ ៖</p>
                <Input.TextArea
                  value={UserLetters_Clothing}
                  onChange={onChange_UserLetters_Clothing}
                  className="styleTageStatusForm"
                  rows={2}
                  placeholder="បញ្ចូល សម្លៀកបំពាក់....."
                />

                <p style={StyleText_Label}>ថ្ងៃខែរៀបចំ​ លិខិត ៖</p>
                <DatePicker
                  format={"DD/MM/YYYY"}
                  value={dayjs(UserLetters_DateDocument)}
                  onChange={onChange_UserLetters_DateDocument}
                  style={{ width: "100%", height: 40 }}
                />

                <p style={StyleText_Label}>ថ្ងៃខែ ប្រធានចុះហេត្ថលេខា ៖</p>
                <DatePicker
                  format={"DD/MM/YYYY"}
                  value={dayjs(UserLetters_DateSignature)}
                  onChange={onChange_UserLetters_DateSignature}
                  style={{ width: "100%", height: 40 }}
                />
                <p style={StyleText_Label}>File ឯកសាររបស់លិខិត ៖</p>

                <Upload
                  key={null} //
                  action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                  listType="picture-card"
                  fileList={modifiedFileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  beforeRemove={(file) => false} // Prevent automatic removal of files
                  onRemove={(file) => {
                    return new Promise((resolve, reject) => {
                      Modal.confirm({
                        title: "Are you sure you want to delete this file?",
                        okText: "Yes",
                        cancelText: "No",
                        onOk: () => {
                          handleRemove(file);
                        },
                        // onCancel: () => {reject}
                      });
                    });
                  }}
                >
                  {fileList.length >= 8 ? null : uploadButton}
                </Upload>

                <p style={{ margin: 5, color: "darkgray" }}>
                  សរុបឯកសារ ៖ <Tag color="#f50">{totalCount}</Tag>{" "}
                </p>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          width={500}
          title="មើលលិខិតច្បាប់ដើម"
          className="custom-form-item"
          open={visableModelPreviewFile}
          onCancel={onCanceDelete}
          footer={[
            <div>
              <Button onClick={onCLoseModelDelete}>Close</Button>
            </div>,
          ]}
        >
          <div style={{ height: "auto" }}>
            <Upload
              key={null} //
              listType="picture"
              fileList={modifiedFileList}
              onPreview={handlePreview}
              //itemRender={itemRender}
            >
              {fileList.length >= 8 ? null : null}
            </Upload>
          </div>
        </Modal>

        <Modal
          open={previewOpen}
          // title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          {previewImage &&
            (previewImage.endsWith(".png") ||
              previewImage.endsWith(".jpg") ||
              previewImage.endsWith(".jpeg")) && (
              <img
                style={{
                  width: "80%",
                }}
                src={previewImage}
              />
            )}
          {!previewImage ||
            (!previewImage.endsWith(".png") &&
              !previewImage.endsWith(".jpg") &&
              !previewImage.endsWith(".jpeg") && (
                <dvi>
                  <p
                    style={{
                      margin: 5,
                      color: "darkgray",
                      paddingTop: 10,
                      fontFamily: "Content ,sans-serif",
                    }}
                  >
                    ឈ្មោះឯកសារ : {previewTitle}
                  </p>
                  <img
                    style={{
                      width: "50%",
                    }}
                    src=" https://icon-library.com/images/file-browser-icon/file-browser-icon-4.jpg"
                  />
                </dvi>
              ))}

          <Button
            type="primary"
            className="buttonstylekhmer"
            onClick={onClick_Download}
          >
            ទាញយក / Download
          </Button>
        </Modal>

        <Modal
          title="ទាញរបាយការណ៍"
          className="custom-modal" // Use the custom-modal class for responsive styles
          open={DownloadReport}
          onCancel={() => {
            setDownloadReport(false);
          }}
          footer={[
            <div>
              <Button className="custom-form-item" type="primary">
                ទាញយក (Excel)
              </Button>
            </div>,
          ]}
        >
          <div style={{ width: "520px" }}>
            <Space>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Space style={{ width: "100%" }}>
                  ច្បាប់ចាប់ពីថ្ងៃ ទី ខែ :
                  <DatePicker
                  //  onChange={handleDateChange_Start}
                  />
                </Space>
              </div>
              <div>
                <Space>
                  ដល់ថ្ងៃ ទី ខែ :
                  <DatePicker
                  //  onChange={handleDateChange_End}
                  />
                </Space>
              </div>
            </Space>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default LettersPage;
